/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card >
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Mes rendez-vous
        </MDTypography>

      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="info"
          icon="notifications"
          title="Rendez-vous avec Julien Boyer"
          dateTime="22 DEC 7:20 PM"
        />
        <TimelineItem
          color="success"
          icon="notifications"
          title="1ère séance avec Simon Thénoz"
          dateTime="21 DEC 11 PM"
        />
        <TimelineItem
          color="info"
          icon="notifications"
          title="Entretien préliminaire avec Maxime Doublet"
          dateTime="21 DEC 9:34 PM"
        />


      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
