/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControl from '@mui/material/FormControl';

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { InputLabel, Button, Icon, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import createSeanceContext from "context/createSeanceContext";
import { useContext, useRef, useEffect, useState } from "react";
import { useAuth } from "context/authContext";
import { createSeance } from "model/seances";
import { postSeanceMedia } from "model/seances";
import { updateSeance } from "model/seances";
import ReactPlayer from "react-player";
import { updateSeanceMedia } from "model/seances";
import { getSeanceData } from "model/seances";
import { getSeanceMediaUrl } from "model/seances";
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from "react-drag-drop-files";
import { uploadBytesResumable, ref as refStorage, getDownloadURL, } from "firebase/storage";
import { db, storage } from "config";
import { getAllPractitionersData } from "model/praticionersData";
import MDProgress from "components/MDProgress";

function EditSeance() {
    const [seanceData, setSeanceData] = useState();
    const [media, setMedia] = useState();
    const [urlSource, setUrlSource] = useState();
    const [oldMediaSize, setOldMediaSize] = useState(0);

    const [loadingData, setLoadingData] = useState(true);
    const [practitionersData, setPractitionersData] = useState();
    const [maxGiga, setMaxGiga] = useState(0);
    const [overStockage, setOverStockage] = useState(false);
    const [mediaName, setMediaName] = useState();

    const [file, setFile] = useState(null);
    const [loader, setLoader] = useState(false);
    const [labelDrop, setLabelDrop] = useState("Téléchargez ou déposez un fichier ici");

    const [progressUpload, setProgressUpload] = useState(0);
    const fileTypes = ["MP3", "MP4"];

    const location = useLocation()

    const id = location.state.seanceId
    const { user } = useAuth();
    const navigate = useNavigate()
    const fileInput = useRef();
    const selectFile = () => {
        fileInput.current.click();
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required("Ce champ est obligatoire"),
        method: Yup.string()
            .required("Vous devez choisir une thématique"),
        thematic: Yup.string()
            .required("Vous devez choisir une thématique"),
        description: Yup.string(),
    });
    let initialValues = {
        title: "",
        method: "",
        thematic: "",
        description: "",
    };
    if (seanceData) {
        initialValues = seanceData
    }
    const handleFile = () => {
        if (
            fileInput.current.files[0].type.indexOf("audio") !== -1 ||
            fileInput.current.files[0].type.indexOf("video") !== -1
        ) {
            setSeanceData({
                ...seanceData,
                media_name: fileInput.current.files[0].name,
                media_size: fileInput.current.files[0].size
            });
            setMedia(fileInput.current.files[0])
            setUrlSource(URL.createObjectURL(fileInput.current.files[0]));


            // setUrlSource()
        } else warn("Veuillez fournir un fichier audio ou vidéo au format valide");
    };

    const handleSubmit = async (values) => {
        // if (seanceData.thematic !== "autre" && seanceData.method !== "autre") {
        //     delete seanceData.other_method;
        //     delete seanceData.other_thematic;
        // }

        updateSeance(user, id, {
            ...seanceData, ...values
        });
        if (media) {
            setLoader(true);
            const newMediaUrl = await updateSeanceMedia(
                user,
                media,
                id,
                seanceData.media_name,
                seanceData.media_url,
                oldMediaSize
            );
            updateSeance(user, id, {
                media_url: newMediaUrl,
            });
            const storageRef = refStorage(
                storage,
                `practitioners/${user.uid}/seances/${id}/${seanceData.media_name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgressUpload(Math.round(progress))
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // });
                    navigate("/seances/profil-seance", { state: { seanceId: id } });

                    setSeanceData({});
                })
        } else {
            navigate("/seances/profil-seance", { state: { seanceId: id } });

            setSeanceData({});
        }
        //   navigate
        // navigate("/seances/profil-seance", { state: { seanceId: id } })
    };
    const handleChange = (file) => {
        setLabelDrop("Fichier validé")
        setFile(file);
        setMedia(file);

        let mediaSize = file.size
        let mediaTotalSize = mediaSize + practitionersData.seance_size


        if ((mediaTotalSize / (1000 * 1000 * 1000)).toFixed(3) > maxGiga) {
            setOverStockage(true)
        } else {
            setOverStockage(false)
        }
        setMediaName(file.name)

        setSeanceData({
            ...seanceData,
            media_type: file.type,
            media_name: file.name,
            media_size: file.size,
        });

    };
    const getData = async (user, id) => {
        const sData = await getSeanceData(user, id);
        setSeanceData(sData);
        setMediaName(sData.media_name)
        setOldMediaSize(sData.media_size)
        if (sData) {
            const url = await getSeanceMediaUrl(sData.media_url);
            setUrlSource(url);
        }
        setLoadingData(false);
        const data = await getAllPractitionersData(user);
        setPractitionersData(data);
        switch (data.status) {
            case 'FREE':
                setMaxGiga(1)
                break;
            case 'STARTER':
                setMaxGiga(5)
                break;
            case 'PRO':
                setMaxGiga(10)

                break;
            case 'ENTERPRISE':
                setMaxGiga(50)

                break;
            default:
                setMaxGiga(1)

        }
    };
    // useEffect(() => {
    //     const fileReader = new FileReader();
    //     fileReader.onload = () => setUrlSource(fileReader.result);

    //     // if (media) fileReader.readAsDataURL(media);
    //   }, [seanceData]);
    useEffect(() => {
        getData(user, id);
    }, [id, loadingData, user]);

    return (

        <DashboardLayout>

            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid lg={2}></Grid>
                <Grid sm={12} lg={7} >

                    <MDBox pt={6} pb={3} >
                        {seanceData && <Card >
                            <MDBox
                                variant="gradient"
                                bgColor="light"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                                    Modifier Séance
          </MDTypography>
                                <MDTypography display="block" variant="button" color="dark" my={1}>
                                    Modifier les informations de votre séance
          </MDTypography>
                            </MDBox>
                            {loader === true ? <MDBox pt={4} pb={3} px={3} >

                                <MDTypography display="block" variant="button" fontWeight="bold" color="dark" >
                                    Stockage de la séance en cours ...
      </MDTypography>
                                <MDProgress label value={progressUpload} />
                            </MDBox> : <MDBox pt={4} pb={3} px={3} >
                                    <MDBox >
                                        <MDBox mt={4} mb={1} textAlign="center" >
                                            {/* <MDButton variant="gradient" color="secondary" component="label" onClick={selectFile} endIcon={<Icon fontSize="small">upload</Icon>} >
                                            Modifier fichier
            

                                        </MDButton> */}
                                            <FileUploader handleChange={handleChange} name="file" label={labelDrop} types={fileTypes} />
                                            {/* <input hidden ref={fileInput}
                                                onChange={handleFile}
                                                accept="audio/*, video/*" type="file" /> */}
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDTypography display="block" variant="button" fontWeight="bold" color="dark" >
                                                    {mediaName}
                                                </MDTypography>
                                                {/* <MDBox >
                                                <ReactPlayer controls height={100} width
                                                    // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                                    url={urlSource}></ReactPlayer>
                                            </MDBox> */}
                                            </MDBox>
                                            {overStockage && <MDBox justifyContent="space-between" alignItems="center" pt={2} px={2} style={{ backgroundColor: "white" }}>
                                                <MDBox display="flex" lineHeight={1} justifyContent="center">
                                                    <MDTypography variant="button" justifyContent="center" fontWeight="bold" color="error">
                                                        Fichier trop volumineux. </MDTypography>
                                                </MDBox>
                                                <MDBox display="flex" justifyContent="center" lineHeight={1}>

                                                    <MDTypography variant="button" mb={2} fontWeight="bold" color="error">
                                                        Améliorez votre plan pour augmenter votre stockage.</MDTypography>

                                                </MDBox>
                                                <MDBox display="flex" justifyContent="center" lineHeight={1}>
                                                    <MDButton aria-describedby={id} pt={2} onClick={() => navigate("/profil")} variant="gradient" color="secondary" endIcon={<Icon fontSize="small">rocket</Icon>}>
                                                        Améliorer
                                        </MDButton>
                                                </MDBox>
                                            </MDBox>}

                                        </MDBox>
                                        <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={
                                            (values) => {
                                                handleSubmit(values)

                                            }

                                        }

                                            render={formProps => {
                                                return (
                                                    <Form>
                                                        <MDBox mb={2}>
                                                            <MDInput type="text" label="Titre" variant="standard" name="title"
                                                                fullWidth
                                                                value={formProps.values.title}
                                                                onChange={formProps.handleChange}
                                                                error={Boolean(formProps.errors.title)}
                                                                helperText={formProps.errors.title} />
                                                        </MDBox>

                                                        <MDBox mb={4}>
                                                            <FormControl variant="standard" fullWidth>
                                                                <InputLabel id="demo-simple-select-label" error={Boolean(formProps.errors.thematic)}>Thématique</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Thématique"
                                                                    name="thematic"
                                                                    value={formProps.values.thematic}
                                                                    onChange={formProps.handleChange}
                                                                    error={Boolean(formProps.errors.thematic)}
                                                                >
                                                                    <MenuItem value="découverte">Découverte</MenuItem>
                                                                    <MenuItem value="sommeil">Sommeil</MenuItem>
                                                                    <MenuItem value="énergie">Energie</MenuItem>
                                                                    <MenuItem value="grossesse">Grossesse</MenuItem>
                                                                    <MenuItem value="stress">Stress</MenuItem>
                                                                    <MenuItem value="enfants">Enfant</MenuItem>
                                                                    <MenuItem value="émotions">Emotion</MenuItem>
                                                                    <MenuItem value="mental">Mental</MenuItem>
                                                                    <MenuItem value="autre">Autre</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                        </MDBox>
                                                        <MDBox mb={4}>
                                                            <FormControl variant="standard" fullWidth>
                                                                <InputLabel id="demo-simple-select-label" error={Boolean(formProps.errors.method)}>Méthode</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Thématique"
                                                                    name="method"
                                                                    value={formProps.values.method}
                                                                    onChange={formProps.handleChange}
                                                                    error={formProps.touched.method && Boolean(formProps.errors.method)}
                                                                >
                                                                    <MenuItem value="sophrologie">Sophrologie</MenuItem>
                                                                    <MenuItem value="yoga">Yoga</MenuItem>
                                                                    <MenuItem value="méditation">Méditation</MenuItem>
                                                                    <MenuItem value="cohérence cardiaque">Cohérence cardiaque</MenuItem>
                                                                    <MenuItem value="voyage sonore">Voyage sonore</MenuItem>
                                                                    <MenuItem value="autre">Autre</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                        </MDBox>


                                                        <MDBox mb={2}>
                                                            <MDInput type="" label="Description" multiline rows={5} name="description"
                                                                fullWidth
                                                                value={formProps.values.description}
                                                                onChange={formProps.handleChange}
                                                                error={Boolean(formProps.errors.description)}
                                                                helperText={formProps.touched.description && formProps.errors.description} />
                                                        </MDBox>


                                                        <MDBox mt={4} mb={1} textAlign="center">
                                                            <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} type="submit" >
                                                                Modifier Séance
                                        </MDButton>
                                                        </MDBox>
                                                    </Form>);
                                            }}>
                                        </Formik>
                                    </MDBox>
                                </MDBox>}
                        </Card>}
                    </MDBox>
                </Grid>

            </Grid>
        </DashboardLayout>
    );
}

export default EditSeance;
