/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background1.jpg";
import { Grid } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import FaqCollapse from "components/FaqCollapse";
import Footer from "examples/Footer";
import ReactPlayer from "react-player";

import { useState } from "react";

// const { createUser } = useAuth();



function Faq() {
    const [collapse, setCollapse] = useState(false);

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDBox
                width="calc(100% - 0.2rem)"
                borderRadius="xl"
                mx={2}
                my={2}
                pb={20}

            />
            <MDBox mb={6} mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 0.2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card >
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    F A Q
                                </MDTypography>

                            </MDBox>
                            <Grid item xs={12} md={10} justifyContent="center">

                                {/* <MDBox mx={2} mb={2} mt={2}>
    <MDTypography variant="body2"  color="text">
        Le site web zenego.pro et l’application mobile « Détendez-vous avec Zenego » est détenu par TAIWA, qui est un contrôleur de données de vos données personnelles.
        Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par zenego.pro et l’application mobile « Détendez-vous avec Zenego », qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser le site web de zenego.fr et l’application mobile « Détendez-vous avec Zenego ».
        Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.
        
</MDTypography>
</MDBox> */}
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <FaqCollapse
                                    title="Comment ajouter des clients ? "
                                    open={collapse === 1}
                                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                                >
                                    <MDBox display="flex" justifyContent="center">

                                        <ReactPlayer controls
                                            // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                            url={"https://youtu.be/5RE2Df2ywXY"}

                                        />
                                    </MDBox>
                                    <br />
                                    1️⃣ Sur la page "Tableau de bord", cliquez sur le bouton " Ajouter client"
                                    <br />
                                    2️⃣ Remplir les informations client avec les champs obligatoires : Nom/Prénom/E-mail, et remplir les autres champs si souhaités.
                                    <br />
                                    3️⃣ Appuyez sur "Ajouter client" pour valider le nouveau client
                                    <br />
                                    4️⃣ Retrouvez tous vos clients dans la partie "Clients" du site Zenego</FaqCollapse>
                                <FaqCollapse
                                    title="Comment ajouter des séances ? "
                                    open={collapse === 2}
                                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                                >
                                    <MDBox display="flex" justifyContent="center">

                                        <ReactPlayer controls
                                            // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                            url={"https://youtu.be/Je3kCmnSx1U"}

                                        />
                                    </MDBox>
                                    <br />
                                    1️⃣ Se rendre dans la catégorie "Séances" puis cliquez sur le bouton "Ajouter séance"
                                    <br />
                                    2️⃣ Importez le fichier audio de la séance (mp3, mp4, mov, pdf, png, ou jpg) et remplir les champs demandés : Titre/Thématique/Méthode/Description. Appuyez sur "Ajouter séance" pour valider la séance
                                    <br />
                                    3️⃣ Retrouvez toutes vos séances dans la partie "Séances" de Zenego
</FaqCollapse>
                                <FaqCollapse
                                    title="Comment associer et partager des séances à des clients ? "
                                    open={collapse === 3}
                                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                                >
                                    <MDBox display="flex" justifyContent="center">

                                        <ReactPlayer controls
                                            // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                            url={"https://youtu.be/cJEdF6NOlVA"}

                                        />
                                    </MDBox>
                                    <br />
                                    1️⃣ Se rendre dans la catégorie "Séances" puis cliquez sur le bouton "Voir" de la séance que vous souhaitez associer à un client
                                   <br />
                                    2️⃣ Une fois sur la séance sélectionnée, cliquer sur le bouton "Modifier association"
                                    <br />
                                    3️⃣ Choisir un ou plusieurs clients à qui associer la séance
                                    <br />
                                    <br />
                                    Pour partager une séance à un ou plusieurs clients une fois la séance associée :
                                    <br />
                                    1️⃣ Dans la section "Clients" sélectionner le client à qui vous souhaitez partager une séance. Puis cliquez sur le bouton "Partager séances", le client recevra donc un mail l'informant que son praticien lui partage une séance.
                                    <br />
                                    2️⃣ Un message de confirmation de partage s’affichera sur votre écran et le client recevra un mail pour l’informer que le praticien lui a partagé une séance
                                    <br />

                                </FaqCollapse>
                                <FaqCollapse
                                    title="Comment souscrire à un abonnement ?"
                                    open={collapse === 4}
                                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                                >
                                    <MDBox display="flex" justifyContent="center">

                                        <ReactPlayer controls
                                            // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                            url={"https://youtu.be/vKjMMeC5-FI"}

                                        />
                                    </MDBox>
                                    <br />
                                    1️⃣ Se rendre dans la catégorie "Profil" puis cliquez sur le bouton "S'abonner" pour accéder aux autres options d'abonnement
                                <br />
                                2️⃣ Sélectionner l'abonnement de votre choix en cliquant sur "M'abonner", puis sur la page suivante entrez vos informations de paiement afin de souscrire à l'abonnement choisi
                                </FaqCollapse>
                            </Grid>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Faq;
