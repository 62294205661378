/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { InputLabel, Button, Icon, Grid, Stack } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAuth } from "context/authContext";
import { useState, useEffect } from "react";
import { getAllPractitionersData } from "model/praticionersData";
import MDAvatar from "components/MDAvatar";
import { updatePractitionersData } from "model/praticionersData";
import { getAuth, updateEmail } from "firebase/auth";
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
const auth = getAuth();
function FormEdit() {
  const { user, upload } = useAuth();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [photoURL, setPhotoURL] = useState();
  const [practitionersData, setPractitionersData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate()

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object().shape({
    lastname: Yup.string()
      .required("Ce champ est obligatoire"),
    firstname: Yup.string()
      .required("Ce champ est obligatoire"),
    phone: Yup.string()
      .matches(phoneRegExp, 'téléphone invalide'),
    adress: Yup.string()
      .required("Ce champ est obligatoire"),
    postCode: Yup.number()
      .min(5, "le code postal est trop court"),
    city: Yup.string()
      .required("Ce champ est obligatoire"),

    country: Yup.string()
      .required("Ce champ est obligatoire"),
    website_url: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Site web invalide'
      ),
    description: Yup.string(),
  });
  let initialValues = {
    lastname: "",
    firstname: "",
    phone: "",
    adress: "",
    postCode: "",
    city: "",
    country: "",
    website_url: "",
    description: "",
  };
  if (practitionersData) {
    initialValues = practitionersData
  }

  const handleAvatarSelection = async (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
      setPhotoURL(URL.createObjectURL(e.target.files[0]));
    }
  };


  const getData = async (user) => {
    const data = await getAllPractitionersData(user);
    setAvatar(user.photoURL)
    setPhotoURL(data.photoURL)
    setPractitionersData(data);
    setDataLoading(false);
    // // if (!data && !dataLoading) {
    //   updateDataIfGoogleSignIn(user);
    //   setGoogleUpdateLoading(false);
    // }
  };

  useEffect(() => {
    getData(user);

  }, [user]);
  const handleSubmit = async (values) => {

    try {
      // if (avatar) await upload(avatar, user, setLoading);

      if (photoURL) await upload(avatar, user);
      await updatePractitionersData(user, { ...practitionersData, ...values });
      navigate("/profil");
    } catch (error) {
      console.error(error);
    }
  };
  return (

    <DashboardLayout>

      <DashboardNavbar />


      {practitionersData && <Grid container >
        <Grid lg={2}>

        </Grid>
        <Grid sm={12} lg={7} >

          <MDBox pt={6} pb={3} >

            <Card >

              <MDBox
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                  Modifier Profil
          </MDTypography>
                <MDTypography display="block" variant="button" color="dark" my={1}>
                  Modifier les informations de votre profil
          </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3} textAlign="center">

                <MDBox >
                  <MDBox sx={{ display: 'flex', justifyContent: "center" }} >
                    <MDAvatar alt="profile-image" src={photoURL || user.photoURL} size="xxl" shadow="sm" />
                    {/* {!photoURL && !user.photoURL && <MDAvatar alt="profile-image" src={"https://zenego.s3.eu-west-1.amazonaws.com/avatar.png"} size="xxl" shadow="sm" />} */}

                  </MDBox>
                  <MDBox mt={4} mb={1} textAlign={"center"} >

                    <MDButton mt={4} variant="gradient" color="secondary" component="label" endIcon={<Icon fontSize="small">upload</Icon>} >
                      modifier image
                    <input hidden accept="image/*" multiple type="file" onChange={handleAvatarSelection} />

                    </MDButton>
                  </MDBox>
                  <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={
                    (values) => {
                      handleSubmit(values)

                    }

                  }

                    render={formProps => {
                      return (
                        <Form>
                          <MDBox mb={2}>
                            <MDInput type="text" label="Nom" variant="standard" name="lastname"
                              fullWidth
                              value={formProps.values.lastname}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.lastname)}
                              helperText={formProps.errors.lastname} />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput type="text" label="Prénom" variant="standard" name="firstname"
                              fullWidth
                              value={formProps.values.firstname}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.firstname)}
                              helperText={formProps.errors.firstname} />
                          </MDBox>

                          <MDBox mb={2}>
                            <MDInput type="phone" label="Téléphone" variant="standard" name="phone"
                              fullWidth
                              value={formProps.values.phone}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.phone)}
                              helperText={formProps.errors.phone} />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput type="adress" label="Adresse" variant="standard" name="adress"
                              fullWidth
                              value={formProps.values.adress}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.adress)}
                              helperText={formProps.errors.adress} />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput type="postCode" label="Code postal" variant="standard" name="postCode"
                              fullWidth
                              value={formProps.values.postCode}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.postCode)}
                              helperText={formProps.errors.postCode} />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput type="city" label="Ville" variant="standard" name="city"
                              fullWidth
                              value={formProps.values.city}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.city)}
                              helperText={formProps.errors.city} />
                          </MDBox>

                          <MDBox mb={2}>
                            <MDInput type="country" label="Pays" variant="standard" name="country"
                              fullWidth
                              value={formProps.values.country}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.country)}
                              helperText={formProps.errors.country} />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput type="" label="Site web" variant="standard" name="website_url"
                              fullWidth
                              value={formProps.values.website_url}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.website_url)}
                              helperText={formProps.errors.website_url} />
                          </MDBox>

                          <MDBox mb={2}>
                            <MDInput type="" label="Description" multiline rows={5} name="description"
                              fullWidth
                              value={formProps.values.description}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.description)}
                              helperText={formProps.errors.description} />
                          </MDBox>


                          <MDBox mt={4} mb={1} textAlign="center">
                            <MDButton variant="gradient" color="success" type="submit"  >
                              Modifier profil
              </MDButton>
                          </MDBox>
                        </Form>);
                    }}>
                  </Formik>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>

      </Grid>}
    </DashboardLayout>
  );
}

export default FormEdit;
