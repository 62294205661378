/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDAvatar from "components/MDAvatar";
import { Icon, Modal, FormControl, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Snackbar, Alert } from "@mui/material";
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import SeanceCard from "./components";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "context/authContext";
import { getClientData } from "model/client";
import { backgroundColor } from "colorszenego";
import { getSeanceData } from "model/seances";
import { getSeancesList } from "model/seances";
import { updateSeance } from "model/seances";
import { updateClient } from "model/client";
import { deleteClient } from "model/client";
import Timeline from "./components/Timeline";
import * as axios from "axios";
import { getAllPractitionersData } from "model/praticionersData";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
function ProfileClient() {
    const navigate = useNavigate()
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [emailStatus, setEmailStatus] = useState("success");
    const [emailInfo, setEmailInfo] = useState("Une erreur c'est produite");

    const [clientId, setClientId] = useState();
    const [clientData, setClientData] = useState();
    const [practitionersData, setPractitionersData] = useState();

    const [seanceList, setSeanceList] = useState([]);
    const [selectedSeanceList, setSelectedSeanceList] = useState([]);
    const { user } = useAuth();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setEmailSuccess(false)
    };
    const handleSubmit = async () => {

        const selectedSeanceIDList = selectedSeanceList.map((seance) => seance.id);

        updateClient(user, clientData.id, { seanceList: selectedSeanceIDList });
        const currentSeanceIDList = seanceList.map((seance) => seance.id);
        const removeSeanceIDList = [];
        currentSeanceIDList.forEach((seanceID) => {
            if (!selectedSeanceIDList.includes(seanceID))
                removeSeanceIDList.push(seanceID);
        });
        for (const seanceID of selectedSeanceIDList) {
            const seanceData = await getSeanceData(user, seanceID);
            let newClientList = [clientData.id];
            if (seanceData.clientList && !seanceData.clientList?.includes(clientData.id))
                newClientList = [...seanceData.clientList, clientData.id];
            if (seanceData.clientList && seanceData.clientList?.includes(clientData.id))
                newClientList = seanceData.clientList;
            updateSeance(user, seanceID, {
                clientList: newClientList,
            });
        }
        for (const seanceID of removeSeanceIDList) {
            const seanceData = await getSeanceData(user, seanceID);

            const index = seanceData.clientList?.indexOf(clientData.id);

            if (seanceData.clientList && seanceData.clientList?.includes(clientData.id)) {

                delete seanceData.clientList[index];
                updateSeance(user, seanceID, {
                    clientList: seanceData.clientList,
                });
            }
        }
        getData(user, location.state.clientId)
        handleClose()
    };
    const shareClient = async () => {
        if (selectedSeanceList.length > 0) {
            try {
                // const analytics = getAnalytics();
                // logEvent(analytics, 'share_seance', {
                //     seance_nb: selectedSeanceList.length,
                // });
                const emailSend = await axios.post(`${process.env.REACT_APP_URL_SERVER}/email-client`, {
                    emailClient: clientData.email,
                    emailPraticien: practitionersData.email,
                    lastnamePraticien: practitionersData.lastname,
                    firstnamePraticien: practitionersData.firstname,
                    codeClient: clientData.id,
                    firstname: clientData.firstname,
                    lastname: clientData.lastname
                })
                if (emailSend.data == true) {
                    setEmailSuccess(true)
                    setEmailStatus("success")
                } else {
                    setEmailSuccess(true)
                    setEmailStatus("error")
                }
            } catch (error) {
                console.log(error);
                setEmailSuccess(true)
                setEmailStatus("error")
            }
        } else {
            setEmailSuccess(true)
            setEmailStatus("error")
            setEmailInfo("Veuillez d'abord associer des séances à votre client")
        }





    }
    const getSelectedSeanceList = async (seanceListId) => {


        let datas = []
        for (let item of seanceListId) {
            let data = await getSeanceData(user, item)
            const id = datas.length
            if (data) {
                data = { ...data, key: data.id }
                datas.push(data)

            }

        }
        setSelectedSeanceList(datas)


    }
    const getData = async (user, id) => {
        const data = await getClientData(user, id)
        setClientData(data)
        if (data) {

            if (data.seanceList) {

                getSelectedSeanceList(data.seanceList)
            }

        }
        // setLoadingData(false);
    };
    const handleDelete = () => {
        deleteClient(user, location.state.clientId);
        handleCloseDelete()
        navigate("/clients")

    };
    useEffect(async () => {
        getData(user, location.state.clientId)
        const data = await getAllPractitionersData(user);
        setPractitionersData(data);
        // setClientData(data)
        // setClientId(location.state.id)

    }, [user]);
    const getList = async (user) => {
        try {
            const list = await getSeancesList(user);

            if (list) {
                setSeanceList(list);

            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        getList(user);

    }, [user])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {clientData && <Snackbar open={emailSuccess} autoHideDuration={5000} onClose={handleCloseDelete} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={handleCloseDelete} variant="filled" severity={emailStatus} sx={{ width: '100%' }}>
                    {emailStatus == "success" ? `Séance partagée à ${clientData.firstname}` : `${emailInfo}`}
                </Alert>
            </Snackbar>}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Supression du client"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer le client?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton variant="text" color="error" onClick={handleDelete}>supprimer</MDButton>
                    <MDButton onClick={handleCloseDelete} >
                        Annuler
          </MDButton>
                </DialogActions>
            </Dialog>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox sx={style}>
                    <Card >
                        {seanceList.length > 0 ? <MDBox pt={4} pb={3} px={3}  >

                            <MDBox component="form" role="form">
                                <MDBox mt={2}>
                                    <MDTypography mb={2} variant="h6" fontWeight="medium" textAlign="center">
                                        Séléctionner les séances à associer :
                                </MDTypography>
                                </MDBox>

                                {clientData && <FormControl variant="standard" fullWidth >
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={seanceList}
                                        getOptionLabel={(option) => `${option.title}`}

                                        defaultValue={selectedSeanceList}
                                        value={selectedSeanceList}

                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, newInputValue) => {

                                            setSelectedSeanceList(newInputValue);

                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Séances associées"
                                                placeholder="Ajouter séance"
                                            />
                                        )}

                                    />
                                </FormControl>}


                            </MDBox>
                            <MDBox mt={3} text textAlign="center">
                                <MDButton variant="gradient" color="success" onClick={() => handleSubmit()} >
                                    Modifier
</MDButton>
                            </MDBox>

                        </MDBox> :
                            <MDBox pt={4} pb={3} px={3} >
                                <MDBox textAlign="center" lineHeight={1.25} pb={3}>
                                    <MDTypography variant="h6" fontWeight="medium" textAlign="center">Pas encore de séance !</MDTypography>

                                </MDBox>
                                <MDBox textAlign="center" lineHeight={1.25}>
                                    <MDButton variant="gradient" color="success" onClick={() => { navigate("/seances/nouvelle-seance") }} endIcon={<Icon fontSize="small">add</Icon>} >
                                        Ajouter Séance
                  </MDButton>
                                </MDBox>
                            </MDBox>}
                    </Card>
                </MDBox>
            </Modal>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        {clientData && <Card sx={{ height: "100%" }}>
                            <Grid container spacing={3} alignItems="center" >

                                <Grid item m={2}  >
                                    <MDAvatar {...stringAvatar(`${clientData.firstname} ${clientData.lastname}`)} alt="profile-image" size="xl" shadow="sm" />
                                </Grid>
                                <Grid item textAlign="center">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="h5" fontWeight="medium">
                                            {clientData.firstname} {clientData.lastname}
                                        </MDTypography>
                                        <MDBox borderRadius="lg" bgColor={backgroundColor(clientData.thematic)}>
                                            <MDTypography variant="button" fontWeight="bold" color={"white"} textTransform="capitalize">
                                                {clientData.thematic}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>

                                </Grid>
                                <Grid item>
                                    <MDButton variant="text" color="dark" onClick={() => navigate("/clients/profil-client/edit", { state: { clientId: clientData.id } })} startIcon={<Icon fontSize="small">edit</Icon>}>
                                        editer profil
                                </MDButton>
                                </Grid>
                            </Grid>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>

                            </MDBox>
                            <MDBox p={2}><MDButton mb={2} variant="gradient" color="primary" endIcon={<Icon fontSize="small">send</Icon>} onClick={shareClient}>
                                partager séances
                                </MDButton></MDBox>

                            <MDBox p={2}>

                                <MDBox opacity={0.3}>

                                </MDBox>
                                <MDBox>
                                    <MDTypography mb={2} variant="h6" fontWeight="medium">
                                        Information
                                </MDTypography>

                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>

                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Email:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.email}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Téléphone:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.phoneNumber}
                                        </MDTypography>


                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Adresse:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.streetName}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Code Postal:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.postalCode}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Ville:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.city}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            description:
                                    </MDTypography>

                                    </MDBox>
                                    <MDBox lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {clientData.motif}
                                        </MDTypography>
                                    </MDBox>

                                </MDBox>

                            </MDBox>
                            <MDButton variant="text" color="error" onClick={handleClickOpenDelete}>
                                <Icon>delete</Icon>supprimer client
                                </MDButton>
                        </Card>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>

                        <MDBox mb={1.5}>
                            {clientData && <Card sx={{ boxShadow: "none" }}>

                                <MDBox mb={2} pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Séance(s) associée(s)
        </MDTypography>
                                    <MDButton variant="gradient" color="success" onClick={handleOpen} >
                                        Modifier Association
</MDButton>
                                    {/* <MDButton variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton> */}
                                </MDBox>
                                {clientData.seanceList?.map((seance, index) => (
                                    <SeanceCard seance={seance} ></SeanceCard>
                                    // <p>{seance}</p>
                                ))}


                            </Card>
                            }
                        </MDBox>

                    </Grid>
                    {clientData && <Timeline notes={clientData.notes}></Timeline>}

                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout>
    );
}

export default ProfileClient;
