/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Seances from "layouts/seances";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import CoverClient from "layouts/register-client";
import Cover from "layouts/authentication/reset-password/cover";
import ProfileClient from "layouts/profileClient";
// @mui icons
import Icon from "@mui/material/Icon";
import ProfileSeance from "layouts/profileSeance";
import CoverSeance from "layouts/register-seance";
import Profil from "layouts/profile";
import FormEdit from "layouts/profile/components/Form";
import EditClient from "layouts/profileClient/components/Edit";
import EditSeance from "layouts/profileSeance/components/Edit";
import Calendar from "layouts/calendar";
import CalendarView from "layouts/calendar";
import Paywall from "layouts/paywall";
import CGV from "layouts/cgv";
import Policies from "layouts/policies";
import Mentions from "layouts/mentions";
import Faq from "layouts/faq";

const routes = [
  {
    type: "collapse",
    name: "Tableau de bord",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/clients",
    component: <Tables />,
  },

  {
    type: "collapse",
    name: "Séances",
    key: "seances",
    icon: <Icon fontSize="small">folder</Icon>,
    route: "/seances",
    component: <Seances />,
  },
  {
    type: "collapse",
    name: "CGV",
    key: "cgv",
    icon: <Icon fontSize="small">folder</Icon>,
    route: "/cgv",
    component: <CGV />,
  },
  {
    type: "collapse",
    name: "Mentions",
    key: "mentions",
    icon: <Icon fontSize="small">folder</Icon>,
    route: "/mentions",
    component: <Mentions />,
  },

  {
    type: "collapse",
    name: "Profil",
    key: "profil",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/profil",
    component: <Profil />,

  },
  {
    type: "collapse",
    name: "Faq",
    key: "faq",
    icon: <Icon fontSize="small">live_help</Icon>,
    route: "/faq",
    component: <Faq />,
  },
  {
    type: "collapse",
    name: "Edit Profil",
    key: "edit-profil",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/profil/edit",
    component: <FormEdit />,
  },
  {
    type: "collapse",
    name: "Signin",
    key: "signin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/signin",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "reset",
    key: "reset",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/reset",
    component: <Cover />,
  },
  {
    type: "collapse",
    name: "paywall",
    key: "paywall",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/paywall",
    component: <Paywall />,
  },
  {
    type: "collapse",
    name: "Signup",
    key: "signup",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/signup",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Nouveau Client",
    key: "register",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/clients/nouveau-client",
    component: <CoverClient />,
  },
  {
    type: "collapse",
    name: "Nouvelle Séance",
    key: "register-seances",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/seances/nouvelle-seance",
    component: <CoverSeance />,
  },
  {
    type: "collapse",
    name: "Profil Client",
    key: "profil-client",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/clients/profil-client",
    component: <ProfileClient />,
  },
  {
    type: "collapse",
    name: "Edit Client",
    key: "edit-client",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/clients/profil-client/edit",
    component: < EditClient />,
  },
  {
    type: "collapse",
    name: "Profil Séance",
    key: "profil-seance",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/seances/profil-seance",
    component: <ProfileSeance />,
  },
  {
    type: "collapse",
    name: "Edit Séance",
    key: "edit-seance",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/seances/profil-seance/edit",
    component: < EditSeance />,
  },
  {
    type: "collapse",
    name: "vie-privee",
    key: "vie-privee",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/vie-privee",
    component: <Policies />,
  },
];

export default routes;
