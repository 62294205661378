/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "context/authContext";
import { getClientList } from "model/client";
import { backgroundColor } from "colorszenego";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
export default function data() {
  const { user } = useAuth();
  const [clientList, setClientList] = useState([]);
  const navigate = useNavigate()
  const getList = async (user) => {
    try {
      // setIsLoading(true);
      const list = await getClientList(user);

      // setFilterList(list);
      if (list) {
        setClientList(list);

      }
      // if (props.search.length) {
      //   let reg = new RegExp(props.search, "i")
      //   let listing = list.filter((e) => reg.test(e.author.props.firstname))
      //   setClientList(listing);
      // }
      // if (list?.length > 0) setPageNumber(Math.ceil(list.length / 6));
      // else setPageNumber(1);
    } catch (error) {
      console.error(error);
    }
    // } finally {
    //   setIsLoading(false);
    // }
  };
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  useEffect(async () => {
    getList(user)
  }, [user])
  const Author = ({ image, firstname, lastname, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar {...stringAvatar(`${firstname} ${lastname}`)} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {firstname} {lastname}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Theme = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      {/* <MDTypography variant="caption">{description}</MDTypography> */}
    </MDBox>
  );
  const Adress = ({ city, adress, postCode }) => (
    <MDBox lineHeight={1} textAlign="center">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {adress}
      </MDTypography>

      <MDTypography fontWeight="medium" color="text" variant="caption">{postCode} {city}</MDTypography>

    </MDBox>
  );
  if (isMobile) {
    return {
      columns: [
        { Header: "client", accessor: "author", align: "left" },


        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: clientList.map((client) => {

        return {
          author: `${client.firstname} ${client.lastname}`,


          action: (
            <MDTypography component="a" href="/clients/profil-client" variant="caption" color="text" fontWeight="medium">
              <MDButton variant="gradient" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/clients/profil-client", { state: { clientId: client.id } })} >
                voir
            </MDButton>
            </MDTypography>
          ),
        }
      }
      )


    };
  } else {
    return {
      columns: [
        { Header: "client", accessor: "author", align: "left" },
        { Header: "email", accessor: "email", align: "left" },

        { Header: "thematique", accessor: "theme", align: "center" },
        { Header: "numéro", accessor: "number", align: "left" },
        // { Header: "adresse", accessor: "adresse", align: "center" },

        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: clientList.map((client) => {

        return {
          // author: <Author image={team2} firstname={client.firstname} lastname={client.lastname} email={client.email} />,
          author: `${client.firstname} ${client.lastname}`,
          email: `${client.email}`,

          // theme: <MDBox borderRadius="lg" bgColor={backgroundColor(client.thematic)}>
          //   <MDTypography p={1} variant="button" fontWeight="bold" color={"white"} textTransform="capitalize" >
          //     {client.thematic}
          //   </MDTypography>
          // </MDBox>,
          theme: client.thematic,
          number: client.phoneNumber,
          // (
          //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          //     {client.phoneNumber}
          //   </MDTypography>
          // ),
          action: (
            <MDTypography component="a" href="/clients/profil-client" variant="caption" color="text" fontWeight="medium">
              <MDButton variant="gradient" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/clients/profil-client", { state: { clientId: client.id } })} >
                voir
          </MDButton>
            </MDTypography>
          ),
        }
      }
      )


    };
  }

}
