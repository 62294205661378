import { Card } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import { useLocation } from 'react-router-dom';

function Paywall() {
    const location = useLocation()

    // Paste the stripe-pricing-table snippet in your React component
    return (
        <DashboardLayout>
            <MDBox
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                p={2}
                mb={1}
                textAlign="center"
            >
                <stripe-pricing-table pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
                    publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
                    client-reference-id={location.state.customerId}
                    customer-email={location.state.customerEmail}>
                </stripe-pricing-table>
            </MDBox>
        </DashboardLayout>

    );
}

export default Paywall;