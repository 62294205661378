/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// Images
import bgImage from "assets/images/background1.jpg";
import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
function Cover() {

  const [resetSuccess, setResetSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [resetStatus, setResetStatus] = useState("success");
  const [errorReset, setErrorReset] = useState("");

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setResetSuccess(false)
  };

  const sendResetMail = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setResetStatus("success")
        setResetSuccess(true)
        setTimeout(() => {
          navigate("/signin");
        }, 3000)

      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage;
        console.log("error code: ", error.code);
        switch (errorCode) {
          case 'auth/invalid-email':
            errorMessage = "Mauvais e-mail"
            break;
          case 'auth/network-request-failed':
            errorMessage = "Pas de connexion Internet"
            break;
          case 'auth/missing-email':
            errorMessage = "Veuillez indiquer votre e-mail"
            break;
          case 'auth/user-not-found':
            errorMessage = "Aucun utilisateur trouvé"
            break;
          default:
            errorMessage = "Une erreur est survenue"
        }
        setErrorReset(`Erreur: ${errorMessage}`)
        setResetStatus('error')
        setResetSuccess(true)

        // ..
      });

  }
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Snackbar open={resetSuccess} autoHideDuration={5000} onClose={handleCloseDelete} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <Alert onClose={handleCloseDelete} variant="filled" severity={resetStatus} sx={{ width: '100%' }}>
          {resetStatus == "success" ? `Un e-mail de reinitialisation de mot de passe vous a été envoyé !` : `${errorReset}`}
        </Alert>
      </Snackbar>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Mot de passe oublié
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Vous recevrez votre mot de passe par e-mail
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="email" label="E-mail" variant="standard" onChange={(e) => setEmail(e.target.value)} fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" onClick={sendResetMail} fullWidth>
                Envoyer
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
