/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.jpg";

import { set, ref } from "firebase/database";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import '../../../config.js'
import { useState, useEffect } from "react";
import { db } from "../../../config.js";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Footer from "../components/Footer/index.js";
import axios from "axios";

const auth = getAuth();
// const { createUser } = useAuth();
const defaultURL =
  "https://zenego.s3.eu-west-1.amazonaws.com/avatar.png";


function Cover() {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Ce champ est obligatoire"),
    lastName: Yup.string()
      .required("Ce champ est obligatoire"),
    email: Yup.string()
      .email("email invalide")
      .required("l'email est obligatoire"),
    password: Yup.string()
      .required("Le mot de passe est obligatoire")
      .min(8, "Le mot de passe doit être plus long que 8 caractères")
      .max(50, "Le mot de passe doit être plus petit que 50 caractères"),
    termsAndConditions: Yup.bool()
      .oneOf([true], 'Vous devez accepter les CGU pour continuer'),
    // confirmPassword: Yup.string()
    //   .required("Confirmation de mot de passe est obligatoire")
    //   .oneOf(
    //     [Yup.ref("password"), null],
    //     "Le mot de passe de confirmation ne correspond pas"
    //   ),
    // acceptTerms: Yup.bool().oneOf([true], "Accepter les conditions est obligatoire"),
  });
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAndConditions: false,
    // acceptTerms: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      register(values)

    },
  });

  const handleSubmit = async (user, values) => {
    // e.preventDefault();
    try {

      const date = new Date()
      await set(ref(db, `practitioners/${user.uid}`), {
        lastname: values.lastName,
        firstname: values.firstName,
        created: date.toISOString(),
        status: 'FREE',
        id: user.uid,
        seance_nb: 0,
        client_nb: 0,
        seance_size: 0,
        photoURL: defaultURL,
        email: values.email,
      });


      try {
        const emailSend = await axios.post(`${process.env.REACT_APP_URL_SERVER}/email-subscriber`, {
          emailPraticien: values.email,
          firstname: values.firstName,
          lastname: values.lastName
        })
      } catch (error) {
        console.log(error);
      }
      navigate("/profil/edit");
      // await updateProfile(user, { photoURL: defaultURL });

      // router.push("/profile");
    } catch (error) {
      console.log(error);
      setError(
        "Une erreur est survenue, merci de vérifier vos informations de connexion"
      );
    }
  }
  const register = (values) => {

    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        localStorage.setItem("user", JSON.stringify(user))

        handleSubmit(user, values)

        // ...
      })
      .catch((error) => {
        console.log(error);
        setError(
          "Une erreur est survenue, merci de vérifier vos informations de connexion"
        );
        const errorCode = error.code;

        const errorMessage = error.message;
        // ..
      });
  }

  return (
    <MDBox>
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              S'inscrire à Zenego Pro
          </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Entrez vos coordonées pour vous inscrire
          </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox >
              {/* <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            > */}
              <form onSubmit={formik.handleSubmit}>
                <MDBox mb={2}>
                  <MDInput type="text" label="Nom" name="lastName" variant="standard" fullWidth value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName} />

                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="text" label="Prénom" name="firstName" variant="standard" fullWidth value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName} />

                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="email" label="E-mail" name="email" variant="standard" fullWidth value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email} />

                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Mot de passe" name="password" variant="standard" fullWidth value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password} />

                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox name="termsAndConditions"
                    onChange={formik.handleChange}
                  />
                  {/* <Field
                    name="acceptTerms"
                    type="checkbox"
                    className="form-check-input"
                  /> */}
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;J'accepte les&nbsp;
              </MDTypography>

                  <MDTypography
                    component={Link}
                    to="/cgv"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    conditions générales d'utilisation
              </MDTypography>

                  {/* <ErrorMessage
                  name="acceptTerms"
                  component="small"
                  className="text-danger d-block"
                /> */}
                </MDBox>
                <MDBox mb={1}>
                  {formik.errors.termsAndConditions && <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    color="error"
                    textGradient

                  >{formik.errors.termsAndConditions} </MDTypography>}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  {/* <MDButton variant="gradient" color="info" onClick={() => register()} fullWidth>
                    sign in
              </MDButton> */}
                  <MDButton variant="gradient" color="info" type="submit"
                    className="btn btn-primary" fullWidth>
                    S'inscrire
              </MDButton>
                </MDBox>
              </form>
              {/* </Formik> */}
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Déjà un compte?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Se connecter
                </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>

      </BasicLayout>
    </MDBox>
  );
}

export default Cover;
