/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon, Grid, Link } from "@mui/material";
import { backgroundColor } from "colorszenego";
import { useNavigate } from "react-router-dom";
function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "min" : "min") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + " " + mDisplay + " " + sDisplay;
}
function SeanceCard({ seance }) {
    const navigate = useNavigate()

    return (
        <MDBox p={1}>
            <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                color="white"
                alignItems="center"
                p={2}
                sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                }}
            >
                <MDBox height="100%" sx={{ width: "32%" }} lineHeight={1} >

                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {seance.title}
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color={"dark"} textTransform="capitalize">
                        {seance.method}
                    </MDTypography>
                </MDBox>
                <MDBox sx={{ width: "35%" }} lineHeight={1}>
                    <Grid container >
                        <Grid xs={12} md={12} textAlign="center">
                            <MDTypography variant="button" fontWeight="bold" color={"dark"}>
                                {secondsToHms(seance.media_duration)}
                            </MDTypography>

                        </Grid>
                        <Grid xs={12} md={12} textAlign="center">
                            <MDBox borderRadius="lg" bgColor={backgroundColor(seance.thematic)}>
                                <MDTypography borderRadius="lg" ml={1} mr={1} variant="button" fontWeight="bold" color={"white"} textTransform="capitalize">
                                    {seance.thematic}
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>

                </MDBox>
                <MDBox ml="auto" lineHeight={0} color={"white"}>
                    <Link href="/seances/profil-seance">

                        <MDButton variant="text" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/seances/profil-seance", { state: { seanceId: seance.id } })}>
                            voir
</MDButton>
                    </Link>
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default SeanceCard;
