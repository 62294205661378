/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon, Grid } from "@mui/material";
import { useAuth } from "context/authContext";
import { backgroundColor } from "colorszenego";
import { getSeanceData } from "model/seances";
import { useNavigate } from "react-router-dom";
function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "min" : "min") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + " " + mDisplay + " " + sDisplay;
}
function SeanceCard({ seance }) {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [seanceData, setSeanceData] = useState();
    const [followsMe, setFollowsMe] = useState(true);
    const [answersPost, setAnswersPost] = useState(false);
    const [mentionsMe, setMentionsMe] = useState(true);
    const [newLaunches, setNewLaunches] = useState(false);
    const [productUpdate, setProductUpdate] = useState(true);
    const [newsletter, setNewsletter] = useState(false);
    const loadData = async () => {
        const newData = await getSeanceData(user, seance);
        setSeanceData(newData);
        // const newMethod = await getMethod(newData?.method);
        // setMethod(newMethod);
        // const newThematic = await getThematic(newData?.thematic);
        // setThematic(newThematic);
        // setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [seance]);

    return (
        <MDBox p={1}>
            {seanceData && <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                color="white"
                alignItems="center"
                p={2}
                sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                }}
            >
                <MDBox height="100%" sx={{ width: "32%" }} lineHeight={1} >

                    <MDTypography variant="h6" fontWeight="medium" >
                        {seanceData.title}
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color={"dark"}>
                        {seanceData.method}
                    </MDTypography>
                </MDBox>
                <MDBox sx={{ width: "35%" }} lineHeight={1}>
                    <Grid container >
                        <Grid xs={12} md={12} textAlign="center">
                            <MDTypography variant="button" fontWeight="bold" color={"dark"}>
                                {secondsToHms(seanceData.media_duration)}
                            </MDTypography>

                        </Grid>
                        <Grid xs={12} md={12} textAlign="center">
                            <MDBox borderRadius="lg" bgColor={backgroundColor(seanceData.thematic)}>
                                <MDTypography variant="button" fontWeight="bold" color={"white"} >
                                    {seanceData.thematic}
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>

                </MDBox>
                <MDBox ml="auto" lineHeight={0} color={"white"}>
                    <MDButton variant="gradient" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/seances/profil-seance", { state: { seanceId: seanceData.id } })} >
                        voir
          </MDButton>
                </MDBox>
            </MDBox>}
        </MDBox>
    );
}

export default SeanceCard;
