/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import frLocale from '@fullcalendar/core/locales/fr'
import 'moment/locale/fr'
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import { useAuth } from "context/authContext";
import { updateEventsPractitionersData } from "model/praticionersData";
import { DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Dialog, Stack, Checkbox, FormGroup, FormControlLabel, ToggleButtonGroup, ToggleButton, Autocomplete } from "@mui/material";
import { LocalizationProvider, MobileDatePicker, DateTimePicker, StaticTimePicker, TimePicker, DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import * as moment from 'moment'
import 'moment/locale/fr'
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MDInput from "components/MDInput";
import { getClientList } from "model/client";


let eventGuid = 0
function createEventId() {
  return String(eventGuid++)
}
function Calendar({ header, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { user } = useAuth()
  const [currentEvents, setCurrentEvents] = useState([]);
  const [titleEvent, setTitleEvent] = useState();
  const [startEvent, setStartEvent] = useState();
  const [endEvent, setEndEvent] = useState();
  const [allDayEvent, setAllDayEvent] = useState();
  const [selectInfo, setSelectInfo] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());
  const [isFresh, setIsFresh] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [street, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [checkedLocation, setCheckedLocation] = useState(false);
  const [typeEvent, setTypeEvent] = useState('visio');
  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);
  const [noteEvent, setNoteEvent] = useState("");

  const handleTypeEvent = (event, newTypeEvent) => {
    setTypeEvent(newTypeEvent);
  };
  const handleChangeCheck = (event) => {
    setCheckedLocation(event.target.checked);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (newValue) => {
    setStartEvent(newValue);
  };

  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const handleDateSelect = (selectInfo) => {
    handleClickOpen()
    setIsUpdate(false)
    setSelectInfo(selectInfo)
    setTitleEvent('')
    setTypeEvent("visio")
    setCheckedLocation(false)
    setCity('')
    setZipCode('')
    setStreet('')
    setNoteEvent('')
    setSelectedClientList([])
    console.log("selectInfo");
    console.log(selectInfo);
    setStartEvent(moment(moment(selectInfo.startStr).format("YYYY-MM-DD") + " " + moment(selectInfo.startStr).format("hh:mm:ss")).toISOString())
    setEndEvent(moment(moment(selectInfo.startStr).format("YYYY-MM-DD") + " " + moment(selectInfo.startStr).format("hh:mm:ss")).toISOString())
    setAllDayEvent(selectInfo.allDay)
    // clear date selection

  }

  const handleEventChangeClick = (clickInfo) => {
    console.log("clickInfo");
    console.log(clickInfo);


    let calendarApi = clickInfo.view.calendar

    let tab = calendarApi.getEvents().map((event) => event.toPlainObject());
    console.log(tab);
  }

  const handleEventClick = (clickInfo) => {
    console.log("clickInfo");
    console.log(clickInfo.event.toPlainObject());
    setIsUpdate(true)
    setSelectInfo(clickInfo)
    setTitleEvent(clickInfo.event.toPlainObject().title)
    setTypeEvent(clickInfo.event.toPlainObject().extendedProps.type)
    setStartTime(clickInfo.event.toPlainObject().start)
    setStartEvent(clickInfo.event.toPlainObject().start)
    setEndEvent(clickInfo.event.toPlainObject().end)
    setEndTime(clickInfo.event.toPlainObject().end)
    setSelectedClientList(clickInfo.event.toPlainObject().extendedProps.listClient)
    setCheckedLocation(clickInfo.event.toPlainObject().extendedProps.checkedLocation)
    setCity(clickInfo.event.toPlainObject().extendedProps.city)
    setZipCode(clickInfo.event.toPlainObject().extendedProps.zipCode)
    setStreet(clickInfo.event.toPlainObject().extendedProps.street)
    setNoteEvent(clickInfo.event.toPlainObject().extendedProps.description)
    handleClickOpen()
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }

    // let calendarApi = clickInfo.view.calendar

    // let tab = calendarApi.getEvents().map((event) => event.toPlainObject());
    // updateEventsPractitionersData(user, tab)
    // console.log(tab);
  }
  let events = rest.events
    ? rest.events.map((el) => ({
      ...el

    }))
    : [];
  useEffect(() => {

    setCalendarEvents([...rest.events])
    getClientList(user)
      .then((res) => {


        const tab = res.map((e) => {
          return {
            ...e, key: e.id
          }
        })
        setClientList(tab);

      })
  }, [])



  const onEventAdded = () => {
    let calendarApi = selectInfo.view.calendar

    if (isUpdate) {
      let tab = calendarApi.getEvents().map((event) => event.toPlainObject());
      tab = tab.map((elt) => {
        if (elt.id === selectInfo.event.toPlainObject().id) {
          elt.title = titleEvent
        }

        return elt
      })
      console.log("update tab");

      console.log(tab);
      events = tab
      setCalendarEvents(events)
      updateEventsPractitionersData(user, tab)
      setOpen(false)
      setIsFresh(true)
      calendarApi.unselect()

    } else {
      if (titleEvent) {

        calendarApi.addEvent({
          id: createEventId(),
          title: titleEvent,
          start: startTime,
          extendedProps: {
            type: typeEvent,
            listClient: selectedClientList,
            checkedLocation: checkedLocation,
            city: city,
            zipCode: zipCode,
            street: street,
          },
          classNames: "success",
          borderColor: "",
          description: noteEvent,
          end: endTime,
          allDay: false
        })

      }
      console.log(calendarApi.getEvents());
      let tab = calendarApi.getEvents().map((event) => event.toPlainObject());
      console.log(tab);

      updateEventsPractitionersData(user, tab)
      setOpen(false)
      calendarApi.unselect()

    };


  }
  return (
    <Card sx={{ height: "100%" }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Événement</DialogTitle>
        <DialogContent>


          <LocalizationProvider dateAdapter={AdapterMoment}
            adapterLocale={'frFR'}
          >
            <Stack mt={2} mb={3} spacing={2}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Titre"
                type="title"
                fullWidth
                value={titleEvent}
                onChange={(e) => setTitleEvent(e.target.value)}
                variant="outlined"

              />
              <ToggleButtonGroup
                color="dark"
                value={typeEvent}
                exclusive
                onChange={handleTypeEvent}
                aria-label="text alignment"
              >
                <ToggleButton value="visio" >
                  {/* <MDTypography variant="h6" fontWeight="medium">
                        Plan actuel
                </MDTypography> */}
                  Visio
      </ToggleButton>
                <ToggleButton value="présentiel" >
                  Présentiel
      </ToggleButton>
                <ToggleButton value="autre" >
                  autre
      </ToggleButton>

              </ToggleButtonGroup>
              <Autocomplete
                multiple
                id="tags-standard"
                options={clientList}
                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}

                defaultValue={selectedClientList}
                value={selectedClientList}

                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newInputValue) => {
                  console.log("selectedClientList");

                  setSelectedClientList(newInputValue);
                  console.log(selectedClientList);

                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Ajouter client"
                    placeholder="Ajouter client"
                  />
                )}
              // multiple
              // id="tags-standard"
              // options={clientList}
              // getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
              // defaultValue={selectedClientList}

              // // onChange={(e) => setSelectedClientList(e.target.value)}
              // renderInput={(params) => {
              //     (

              //         <TextField
              //             {...params}
              //             variant="standard"
              //             label="Multiple values"
              //             placeholder="Favorites"
              //         />
              //     )
              // }}
              />
            </Stack>
            <Stack mt={2} mb={2} spacing={2} direction="row" >

              <DatePicker
                views={['day']}
                onChange={(newValue) => handleChange(newValue)}
                label="Date"
                value={startEvent}
                renderInput={(params) => <TextField {...params} />}

              />


              <MobileTimePicker
                value={startTime}
                label="Début"
                onChange={(newValue) => {
                  setStartTime(newValue)
                  setStartEvent(moment(moment(selectInfo.startStr).format("YYYY-MM-DD") + " " + moment(newValue).format("hh:mm:ss")).toISOString())
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <MobileTimePicker
                value={endTime}
                label="Fin"

                onChange={(newValue) => {
                  setEndTime(newValue)
                  setEndEvent(moment(moment(selectInfo.startStr).format("YYYY-MM-DD") + " " + moment(newValue).format("hh:mm:ss")).toISOString())
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>

          </LocalizationProvider>
          <FormGroup >
            <FormControlLabel control={<Checkbox checked={checkedLocation}
              onChange={handleChangeCheck} ></Checkbox>} label="Ajouter un lieu" />
          </FormGroup>
          {checkedLocation && <Stack mt={2}>


            <MDBox mb={2}>
              <MDInput type="adress" label="Adresse" variant="outlined" value={street}
                onChange={(e) =>
                  setStreet(e.target.value)
                } fullWidth />
            </MDBox>
            <Stack spacing={2} direction="row">
              <MDBox mb={2}>
                <MDInput type="postCode" label="Code postal" variant="outlined" value={zipCode}
                  onChange={(e) =>
                    setZipCode(e.target.value)
                  } fullWidth />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="city" label="Ville" variant="outlined" value={city}
                  onChange={(e) =>
                    setCity(e.target.value)
                  } fullWidth />
              </MDBox>
            </Stack>

          </Stack>}
          <MDBox mb={2}>
            <MDInput type="" label="Note" multiline rows={5} value={noteEvent}
              onChange={(e) =>
                setNoteEvent(e.target.value)
              } fullWidth />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={onEventAdded}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
        {header.title ? (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </MDTypography>
        ) : null}
        {header.date ? (
          <MDTypography component="p" variant="button" color="text" fontWeight="regular">
            {header.date}
          </MDTypography>
        ) : null}
      </MDBox>
      <CalendarRoot p={2} ownerState={{ darkMode }}>


        {calendarEvents && <FullCalendar
          {...rest}
          locale={frLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          selectMirror={true}
          editable={true}
          selectable={true}
          longPressDelay={1}
          events={events}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventsSet={currentEvents}
          eventRemove={function () { console.log("remove"); console.log(events); }}
          height="100%"
        />}
      </CalendarRoot>
    </Card>

  );

}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
