/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import OrdersOverview from "./components/OrdersOverview";
import ReactPlayer from "react-player";
import { Card, Icon, Divider, Link, Alert, Popover } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ClientCard from "./components/ClientCard";
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import SeanceCard from "./components/SeanceCard";
import { getClientNumber } from "model/client";
import { useState, useEffect } from "react";
import { getClientList } from "model/client";
import { auth } from "config";
import { useAuth } from "context/authContext";
import { getSeanceNumber } from "model/seances";
import { getSeancesList } from "model/seances";
import * as moment from 'moment'
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { getAllPractitionersData } from "model/praticionersData";
import MDProgress from "components/MDProgress";
import { useNavigate } from "react-router-dom";


function Dashboard({ context }) {

  const { user } = useAuth()
  const navigate = useNavigate()
  const [clientNumber, setClientNumber] = useState(0);
  const [listClients, setListClients] = useState([]);
  const [listSeances, setListSeances] = useState([]);
  const [seanceNumber, setSeanceNumber] = useState(0);
  const [practitionersData, setPractitionersData] = useState();
  const [maxClient, setMaxClient] = useState("");
  const [maxGiga, setMaxGiga] = useState("");

  const [mediaSize, setMediaSize] = useState("0 GB");

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [datetrial, setDatetrial] = useState();
  const getDataPractitionersData = async (user) => {
    const data = await getAllPractitionersData(user);

    setPractitionersData(data);
    switch (data.status) {
      case 'FREE':
        setMaxClient("5")
        setMaxGiga("1")
        break;
      case 'STARTER':
        setMaxClient("20")
        setMaxGiga("5")
        break;
      case 'PRO':
        setMaxClient("50")
        setMaxGiga("10")

        break;
      case 'ENTERPRISE':
        setMaxGiga("50")
        break;
      default:
        setMaxClient("5")
        setMaxGiga("1")

    }

    const now = moment()
    setDatetrial(15 - now.diff(data.created, 'days'))
    setMediaSize(`${(data.seance_size / (1000 * 1000 * 1000)).toFixed(2)}`)

  }
  const handleClick = (event) => {

    if (practitionersData.status === "FREE" && practitionersData.client_nb >= 5) {
      setAnchorEl(event.currentTarget);
    }
    else if (practitionersData.status === "STARTER" && practitionersData.client_nb >= 20) {
      setAnchorEl(event.currentTarget);

    } else if (practitionersData.status === "PRO" && practitionersData.client_nb >= 50) {
      setAnchorEl(event.currentTarget);

    } else {
      navigate("/clients/nouveau-client")

    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getDataClient = async (user) => {
    const number = await getClientNumber(user);
    number ? setClientNumber(number) : 0;
    const list = await getClientList(user);
    if (list) {
      setListClients(list.slice(0, 3))

    }
  };
  const getDataSeances = async (user) => {
    const number = await getSeanceNumber(user);
    number ? setSeanceNumber(number) : 0;
    const list = await getSeancesList(user);
    if (list) {
      setListSeances(list.slice(0, 3))

    }
  };

  useEffect(() => {
    getDataSeances(user);
    getDataPractitionersData(user)
  }, [user]);

  useEffect(() => {
    getDataClient(user);


  }, [user]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {practitionersData && user && practitionersData.status == "TRIAL" && <Alert variant="filled" severity="info" action={
        <MDButton color="inherit" size="small" href={"http://localhost:4000/create-checkout-session/" + user.uid + "/" + user.email}>
          s'abonner
    </MDButton>
      }>  Version d'essai en cours ( {datetrial} jours restant ) </Alert>} */}
      <MDBox py={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5}>
                <Card>
                  <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                    <MDBox
                      variant="gradient"
                      bgColor={"primary"}
                      color={"primary" === "light" ? "dark" : "white"}
                      coloredShadow={"primary"}
                      borderRadius="xl"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      mt={-3}
                    >
                      <Icon fontSize="medium" color="inherit">
                        {"group"}
                      </Icon>
                    </MDBox>
                    <MDBox textAlign="right" lineHeight={1.25}>
                      <MDTypography variant="button" fontWeight="regular" color="dark">
                        {"Clients"}
                      </MDTypography>
                      <MDBox display="flex" textAlign="right" >
                        <MDTypography variant="h4">{clientNumber} </MDTypography>

                        {practitionersData && practitionersData.status != "ENTERPRISE" && <MDTypography variant="button" fontWeight="regular" color="dark"> /{maxClient}</MDTypography>}
                      </MDBox>


                    </MDBox>
                  </MDBox>
                  {listClients && listClients.length > 0 ?

                    listClients?.map((client) => (
                      <ClientCard client={client} ></ClientCard>
                    )) :
                    <MDBox>
                      <MDBox textAlign="center" lineHeight={1.25} pb={3}>
                        <MDTypography variant="button" fontWeight="regular" color="dark">Pas encore de client </MDTypography>

                      </MDBox>
                      <MDBox textAlign="center" lineHeight={1.25}>
                        <Link href="/clients/nouveau-client">
                          <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} >
                            Ajouter Client
                          </MDButton>
                        </Link>
                      </MDBox>
                    </MDBox>
                  }

                  {listClients && listClients.length > 0 && <MDBox><MDBox textAlign="right" lineHeight={1.25}>

                    <Link href="/clients">

                      <MDButton variant="text" color="dark" endIcon={<Icon fontSize="small">arrow_forward_ios_icon</Icon>} >
                        voir tous
</MDButton>
                    </Link>
                  </MDBox>
                    <MDBox textAlign="center" lineHeight={1.25}>

                      <MDButton aria-describedby={id} onClick={handleClick} variant="gradient" color="primary" endIcon={<Icon fontSize="small">add</Icon>} >
                        Ajouter Client
                          </MDButton>


                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >

                        <MDBox justifyContent="space-between" alignItems="center" pt={2} px={2} style={{ backgroundColor: "white" }}>
                          <MDBox display="flex" lineHeight={1}>
                            <MDTypography variant="button" justifyContent="center" textAlign='center' fontWeight="bold" color="dark">
                              Vous avez atteint la limite de clients pour votre plan. </MDTypography>
                          </MDBox>
                          <MDBox display="flex" justifyContent="center" lineHeight={1}>

                            <MDTypography variant="button" mb={2} fontWeight="bold" color="dark">
                              Améliorez votre plan pour en ajouter plus.</MDTypography>

                          </MDBox>
                          <MDBox display="flex" justifyContent="center" lineHeight={1}>
                            <MDButton aria-describedby={id} pt={2} onClick={() => navigate("/profil")} variant="gradient" color="secondary" endIcon={<Icon fontSize="small">rocket</Icon>}>
                              Améliorer
</MDButton>
                          </MDBox>
                        </MDBox>

                      </Popover>
                    </MDBox>
                  </MDBox>}

                  <Divider />

                </Card>

              </MDBox>

            </Grid>
            {practitionersData && < Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5}>
                {/* <MiniStatisticsCard
                  title={{ text: "Stockage" }}
                  count={mediaSize + ` utilisé sur ${maxGiga} GB`}
                  icon={{ color: "success", component: "memory" }}
                  direction="left"
                /> */}
                <Card>
                  <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                    <MDBox
                      variant="gradient"
                      bgColor={"success"}
                      color={"secondary" === "light" ? "dark" : "white"}
                      coloredShadow={"secondary"}
                      borderRadius="xl"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                    // mt={-3}
                    >
                      <Icon fontSize="medium" color="inherit">
                        {"memory"}
                      </Icon>

                    </MDBox>

                    <MDBox textAlign="right" sx={{ width: '70%' }} lineHeight={1.25}>
                      <MDTypography variant="button" fontWeight="regular" color="dark">
                        {"Stockage"}
                      </MDTypography>
                      <MDTypography variant="h6">{mediaSize + ` utilisé sur ${maxGiga} Giga`}</MDTypography>
                      <MDBox >
                        <MDProgress m={2} label value={((mediaSize * 100) / maxGiga).toFixed()} />
                      </MDBox>
                    </MDBox>

                  </MDBox>

                  <Divider />

                </Card>
              </MDBox>

            </Grid>}
            {/* <OrdersOverview>

            </OrdersOverview> */}


          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5}>
                <Card>
                  <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                    <MDBox
                      variant="gradient"
                      bgColor={"secondary"}
                      color={"secondary" === "light" ? "dark" : "white"}
                      coloredShadow={"secondary"}
                      borderRadius="xl"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      mt={-3}
                    >
                      <Icon fontSize="medium" color="inherit">
                        {"folder"}
                      </Icon>
                    </MDBox>
                    <MDBox textAlign="right" lineHeight={1.25}>
                      <MDTypography variant="button" fontWeight="regular" color="dark">
                        {"Séances"}
                      </MDTypography>
                      <MDTypography variant="h4">{seanceNumber}</MDTypography>
                    </MDBox>
                  </MDBox>
                  {listSeances && listSeances.length > 0 ?
                    listSeances?.map((seance) => (
                      <SeanceCard seance={seance}></SeanceCard>
                    )) :
                    <MDBox>
                      <MDBox textAlign="center" lineHeight={1.25} pb={3}>
                        <MDTypography variant="button" fontWeight="regular" color="dark">Pas encore de séance </MDTypography>

                      </MDBox>
                      <MDBox textAlign="center" lineHeight={1.25}>
                        <Link href="/seances/nouvelle-seance">
                          <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} >
                            Ajouter Séance
                          </MDButton>
                        </Link>
                      </MDBox>
                    </MDBox>
                  }

                  {listSeances && listSeances.length > 0 &&
                    <MDBox textAlign="right" lineHeight={1.25}>
                      <Link href="/seances">

                        <MDButton variant="text" color="dark" endIcon={<Icon fontSize="small">arrow_forward_ios_icon</Icon>} >
                          voir toutes
</MDButton>
                      </Link>
                      <MDBox textAlign="center" lineHeight={1.25}>
                        <Link href="/seances/nouvelle-seance">
                          <MDButton variant="gradient" color="secondary" endIcon={<Icon fontSize="small">add</Icon>} >
                            Ajouter Séance
                          </MDButton>
                        </Link>
                      </MDBox>
                    </MDBox>}
                  <Divider />

                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ height: "100%" }}>
                <Grid container spacing={3} alignItems="center" >


                  <Grid item textAlign="center">
                    <MDBox height="100%" m={2} lineHeight={1} textAlign={"center"}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Comment commencer sur Zenego Pro?
                                    </MDTypography>

                    </MDBox>

                  </Grid>

                </Grid>

                <MDBox p={2}>

                  <MDBox opacity={0.3}>
                  </MDBox>

                  <MDBox display="flex">

                    <ReactPlayer controls
                      // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                      url={"https://youtu.be/yWYGPxKbUCE"}

                    />
                    {/* https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3 */}
                  </MDBox>
                </MDBox>


              </Card>

            </Grid>

          </Grid>
        </Grid>

      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Dashboard;
