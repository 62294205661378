/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background1.jpg";
import { Grid } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import FaqCollapse from "components/FaqCollapse";
import { useState } from "react";

// const { createUser } = useAuth();



function Policies() {
    const [collapse, setCollapse] = useState(false);

    return (
        <PageLayout>

            <MDBox
                width="calc(100% - 0.2rem)"
                borderRadius="xl"
                mx={2}
                my={2}
                pt={6}
                pb={28}

            />
            <MDBox mb={6} mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 0.2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={11} sm={10} md={10} lg={10} xl={10}>
                        <Card >
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    POLITIQUE DE CONFIDENTIALITÉ
                                </MDTypography>

                            </MDBox>
                            <Grid item xs={12} md={10} justifyContent="center">

                                <MDBox mx={2} mb={2} mt={2}>
                                    <MDTypography variant="body2"  color="text">
                                        Le site web zenego.pro et l’application mobile « Détendez-vous avec Zenego » est détenu par TAIWA, qui est un contrôleur de données de vos données personnelles.
                                        Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par zenego.pro et l’application mobile « Détendez-vous avec Zenego », qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser le site web de zenego.fr et l’application mobile « Détendez-vous avec Zenego ».
                                        Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.
                                        
            </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <FaqCollapse
                                    title="Les informations personnelles que nous collectons :"
                                    open={collapse === 1}
                                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                                >
                                 Lorsque vous visitez le site zenego.pro , nous recueillons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez sur le site, nous recueillons des informations sur les pages web ou les produits individuels que vous consultez, sur les sites web ou les termes de recherche qui vous ont renvoyé au site et sur la manière dont vous interagissez avec le site. Nous désignons ces informations collectées automatiquement par le terme "informations sur les appareils". En outre, nous pourrions collecter les données personnelles que vous nous fournissez (y compris, mais sans s'y limiter, le nom, le prénom, l'adresse, etc.) lors de l'inscription afin de pouvoir exécuter le contrat.
                                </FaqCollapse>
                                <FaqCollapse
                                    title="Pourquoi traitons-nous vos données ?"
                                    open={collapse === 2}
                                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                                >
                                  Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour maintenir le site web. Les informations collectées automatiquement sont utilisées uniquement pour identifier les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation du site web.
                                </FaqCollapse>
                                <FaqCollapse
                                    title="Vos droits :"
                                    open={collapse === 3}
                                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                                >
                                    Si vous êtes un résident européen, vous disposez des droits suivants liés à vos données personnelles :
                                    Le droit d'être informé.
                                    Le droit d'accès.
                                    Le droit de rectification.
                                    Le droit à l'effacement.
                                    Le droit de restreindre le traitement.
                                    Le droit à la portabilité des données.
                                    Le droit d'opposition.
                                    Les droits relatifs à la prise de décision automatisée et au profilage.
                                    Si vous souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous.
                                    En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous pourrions avoir avec vous (par exemple, si vous passez une commande par le biais du site), ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que vos informations pourraient être transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.
                                </FaqCollapse>
                                <FaqCollapse
                                    title="​Liens vers d'autres sites web :"
                                    open={collapse === 4}
                                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                                >
                                    Notre site web peut contenir des liens vers d'autres sites web qui ne sont pas détenus ou contrôlés par nous. Sachez que nous ne sommes pas responsables de ces autres sites web ou des pratiques de confidentialité des tiers. Nous vous encourageons à être attentif lorsque vous quittez notre site web et à lire les déclarations de confidentialité de chaque site web susceptible de collecter des informations personnelles.                                </FaqCollapse>
                                <FaqCollapse
                                    title="Sécurité de l'information :"
                                    open={collapse === 5}
                                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                                >
                                   Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé, protégé contre tout accès, utilisation ou divulgation non autorisés. Nous conservons des garanties administratives, techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation non autorisés des données personnelles sous son contrôle et sa garde. Toutefois, aucune transmission de données sur Internet ou sur un réseau sans fil ne peut être garantie.
                                </FaqCollapse>
                                <FaqCollapse
                                    title="Divulgation légale :"
                                    open={collapse === 6}
                                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                                >
Nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise, par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.                                </FaqCollapse>
                                <FaqCollapse
                                    title="​Informations de contact :"
                                    open={collapse === 7}
                                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                                >
                                    Si vous souhaitez nous contacter pour comprendre davantage la présente politique ou si vous souhaitez nous contacter concernant toute question relative aux droits individuels et à vos informations personnelles, vous pouvez envoyer un courriel à pro@zenego.fr.
                                </FaqCollapse>
                              
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>

    );
}

export default Policies;
