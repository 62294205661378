/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import { Dialog, DialogTitle, DialogContent, Stack, DialogActions, Button, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useAuth } from "context/authContext";
import { useState } from "react";
import * as moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { updateClient } from "model/client";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import 'moment/locale/fr'
function TimelineItem({ key, index, color, icon, title, dateTime, description, lastItem, notes }) {
  const location = useLocation();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(moment(dateTime));
  const [titleNote, setTitleNote] = useState(title);
  const [note, setNote] = useState(description);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteNote = () => {

    // delete notes[index]
    const NewNotes = []
    for (const note of notes) {
      if (note != notes[index])
        NewNotes.push(note)
    }
    updateClient(user, location.state.clientId, {
      notes: NewNotes
    });
    window.location.reload(false);
    setOpen(false);
  };
  const handleSubmit = () => {

    notes[index] = {
      titleNote: titleNote,
      dateNote: value.format(),
      note: note
    }

    updateClient(user, location.state.clientId, {
      notes: notes
    });
    window.location.reload(false);
    setOpen(false);
  };
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })} >
      <Dialog open={open} onClose={handleClose} fullWidth>

        <DialogTitle>Carnet de Bord    <MDButton variant="text" color="error" onClick={() => handleDeleteNote()}>
          <Icon>delete</Icon>supprimer note
                                </MDButton></DialogTitle>

        <DialogContent>

          <Stack pt={2} spacing={3}>
            <MDInput label="Titre" value={titleNote} onChange={(e) => setTitleNote(e.target.value)} />
            <LocalizationProvider dateAdapter={AdapterMoment}
              adapterLocale={'frFR'}
            >
              <MobileDatePicker
                label="Date"
                value={value}
                onChange={(newValue) => handleChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <MDInput label="Note" multiline rows={5} value={note} onChange={(e) => setNote(e.target.value)} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>annuler</Button>

          <Button onClick={() => handleSubmit()}>Modifier</Button>
        </DialogActions>
      </Dialog>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit" onClick={handleClickOpen}>{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"} textTransform="capitalize">
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {moment(dateTime).locale('fr').format('LL')}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </ MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
