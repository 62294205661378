/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React components
import { useMaterialUIController } from "context";

// Timeline context
import { TimelineProvider } from "examples/Timeline/context";
import MDButton from "components/MDButton";
import { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Dialog, Stack, Icon } from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import MDInput from "components/MDInput";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/authContext";
import { updateClient } from "model/client";
import timelineData from "layouts/profileClient/components/Timeline/data/timelineData";
import timelineItem from "../TimelineItem/styles";
import TimelineItem from "../TimelineItem";
import * as moment from 'moment'
import 'moment/locale/fr'
function TimelineList({ notes, title, dark }) {

  const location = useLocation();
  const { user } = useAuth();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(moment());
  const [titleNote, setTitleNote] = useState();
  const [note, setNote] = useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {

    if (notes) {
      updateClient(user, location.state.clientId, {
        notes: [...notes, {
          titleNote: titleNote,
          dateNote: value.format(),
          note: note
        }]
      });
    } else {
      updateClient(user, location.state.clientId, {
        notes: [{
          titleNote: titleNote,
          dateNote: value.format(),
          note: note
        }]
      });
    }
    window.location.reload(false);
    setOpen(false);
  };

  return (
    <TimelineProvider value={dark}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Carnet de Bord</DialogTitle>
        <DialogContent>

          <Stack mt={2} spacing={3}>
            <MDInput label="Titre" onChange={(e) => setTitleNote(e.target.value)} />
            <LocalizationProvider dateAdapter={AdapterMoment}
              adapterLocale={'frFR'}
            >
              <MobileDatePicker
                label="Date"
                value={value}
                onChange={(newValue) => handleChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <MDInput label="Note" multiline rows={5} onChange={(e) => setNote(e.target.value)} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>annuler</Button>
          <Button onClick={() => handleSubmit()}>ajouter</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          bgColor={dark ? "dark" : "white"}
          variant="gradient"
          borderRadius="xl"
          sx={{ background: ({ palette: { background } }) => darkMode && background.card }}
        >
          <MDBox pt={3} px={3}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                {title}
              </MDTypography>
              <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} onClick={handleClickOpen}>
                Ajouter
</MDButton>
              {/* <MDButton variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton> */}
            </MDBox>
          </MDBox>
          <MDBox p={2}>
            {notes?.sort((a, b) => moment(b.dateNote) - moment(a.dateNote))?.map((note, index) => (
              <TimelineItem
                key={note.titleNote + "info"}
                index={index}
                notes={notes}
                color={"info"}
                icon={"edit"}
                title={note.titleNote}
                dateTime={note.dateNote}
                description={note.note}
                badges={["controller"]}
                lastItem={false}
              />
            ))}
          </MDBox>
        </MDBox>
      </Card>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
};

export default TimelineList;
