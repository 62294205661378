/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControl from '@mui/material/FormControl';

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { InputLabel, Button, Icon, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import createClientContext from "context/createClientContext";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "context/authContext";
import { createClient } from "model/client";
import { getClientData } from "model/client";
import { updateClient } from "model/client";
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { set } from "firebase/database";
function EditClient() {
    const navigate = useNavigate()
    const location = useLocation()
    const { validation, setValidation } =
        useContext(createClientContext);
    const [clientData, setClientData] = useState();

    const { user } = useAuth();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required("Ce champ est obligatoire"),
        lastname: Yup.string()
            .required("Ce champ est obligatoire"),
        email: Yup.string()
            .email("email invalide")
            .required("l'email est obligatoire"),
        phoneNumber: Yup.string()
            .matches(phoneRegExp, 'téléphone invalide'),
        streetName: Yup.string()
        ,
        postalCode: Yup.number()
            .min(5, "le code postal est trop court"),
        city: Yup.string(),
        country: Yup.string(),
        thematic: Yup.string()
            .required("Vous devez choisir une thématique"),
        motif: Yup.string(),
        // confirmPassword: Yup.string()
        //   .required("Confirmation de mot de passe est obligatoire")
        //   .oneOf(
        //     [Yup.ref("password"), null],
        //     "Le mot de passe de confirmation ne correspond pas"
        //   ),
        // acceptTerms: Yup.bool().oneOf([true], "Accepter les conditions est obligatoire"),
    });

    let initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        streetName: "",
        postalCode: "",
        city: "",
        country: "",
        thematic: "",
        motif: "",

        // acceptTerms: false,
    };
    if (clientData) {
        initialValues = clientData
    }


    // const formik = useFormik({
    //     initialValues,
    //     validationSchema,
    //     enableReinitialize: true,

    //     onSubmit: (values) => {
    //         handleSubmit(values)

    //     },
    // });
    // console.log("formik.values");

    // console.log(formik);
    useEffect(async () => {

        getClientData(user, location.state.clientId).then(setClientData)

        // setClientData(data)
        console.log("edit client");
        // setClientId(location.state.id)

    }, [user]);
    const handleSubmit = async (values) => {
        console.log("values");

        console.log(values);
        const clientId = await updateClient(user, location.state.clientId, { ...values });
        navigate("/clients/profil-client", { state: { clientId: location.state.clientId } })

    };
    return (

        <DashboardLayout>

            <DashboardNavbar />
            <Grid container >
                <Grid lg={2}></Grid>
                <Grid sm={12} lg={7} >

                    <MDBox pt={6} pb={3} >
                        {clientData && <Card >
                            <MDBox
                                variant="gradient"
                                bgColor="light"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                                    Modifier client
          </MDTypography>
                                <MDTypography display="block" variant="button" color="dark" my={1}>
                                    Modifier les informations de votre client
          </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3} >
                                <MDBox >
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={
                                        (values) => {
                                            handleSubmit(values)

                                        }

                                    }

                                        render={formProps => {
                                            return (
                                                <Form>
                                                    <MDBox mb={2}>
                                                        <MDInput type="text" label="Nom" variant="standard"

                                                            name="lastname"
                                                            fullWidth
                                                            value={formProps.values.lastname}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.lastname)}
                                                            helperText={formProps.errors.lastname}
                                                        />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="text" label="Prénom" variant="standard" name="firstname"
                                                            fullWidth
                                                            value={formProps.values.firstname}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.firstname)}
                                                            helperText={formProps.errors.firstname} />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="email" label="Email" variant="standard" name="email"
                                                            fullWidth
                                                            value={formProps.values.email}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.email)}
                                                            helperText={formProps.errors.email} />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="phone" label="Telephone" variant="standard" name="phoneNumber"
                                                            fullWidth
                                                            value={formProps.values.phoneNumber}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.phoneNumber)}
                                                            helperText={formProps.errors.phoneNumber} />

                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="adress" label="Adresse" variant="standard" name="streetName"
                                                            fullWidth
                                                            value={formProps.values.streetName}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.streetName)}
                                                            helperText={formProps.errors.streetName} />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="postCode" label="Code postal" variant="standard" name="postalCode"
                                                            fullWidth
                                                            value={formProps.values.postalCode}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.postalCode)}
                                                            helperText={formProps.errors.postalCode} />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput type="city" label="Ville" variant="standard" name="city"
                                                            fullWidth
                                                            value={formProps.values.city}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.city)}
                                                            helperText={formProps.errors.city} />
                                                    </MDBox>

                                                    <MDBox mb={2}>
                                                        <MDInput type="country" label="Pays" variant="standard" name="country"
                                                            fullWidth
                                                            value={formProps.values.country}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.country)}
                                                            helperText={formProps.errors.country} />
                                                    </MDBox>
                                                    <MDBox mb={4}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-label" error={Boolean(formProps.errors.thematic)}>Thématique</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Thématique"
                                                                name="thematic"
                                                                value={formProps.values.thematic}
                                                                onChange={formProps.handleChange}
                                                                error={Boolean(formProps.errors.thematic)}
                                                            >
                                                                <MenuItem value="découverte">Découverte</MenuItem>
                                                                <MenuItem value="sommeil">Sommeil</MenuItem>
                                                                <MenuItem value="énergie">Energie</MenuItem>
                                                                <MenuItem value="grossesse">Grossesse</MenuItem>
                                                                <MenuItem value="stress">Stress</MenuItem>
                                                                <MenuItem value="enfants">Enfant</MenuItem>
                                                                <MenuItem value="émotions">Emotions</MenuItem>
                                                                <MenuItem value="mental">Mental</MenuItem>
                                                                <MenuItem value="autre">Autre</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </MDBox>


                                                    <MDBox mb={2}>
                                                        <MDInput type="" label="Note" multiline rows={5} name="motif"
                                                            fullWidth
                                                            value={formProps.values.motif}
                                                            onChange={formProps.handleChange}
                                                            error={Boolean(formProps.errors.motif)}
                                                            helperText={formProps.errors.motif} />
                                                    </MDBox>


                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} type="submit">
                                                            Modifier Client
              </MDButton>
                                                    </MDBox>
                                                </Form>
                                            );
                                        }}>
                                        {/* <MDBox mt={4} mb={1} textAlign="center" >
                    <MDButton variant="gradient" color="secondary" component="label" endIcon={<Icon fontSize="small">photo</Icon>} >
                      Ajouter photo
                <input hidden accept="image/*" multiple type="file" />

                    </MDButton>
                  </MDBox> */}


                                    </Formik>
                                </MDBox>
                            </MDBox>
                        </Card>}
                    </MDBox>
                </Grid>

            </Grid>
        </DashboardLayout>
    );
}

export default EditClient;
