/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon, Grid, Link } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
function ClientCard({ client }) {
  const navigate = useNavigate()

  return (
    <MDBox p={1}>
      <MDBox borderRadius="lg"
        component="ul" display="flex" flexDirection="column" p={1} m={0} sx={{
          border: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}>
        <MDBox component="li" display="flex" alignItems="center"  >
          <MDBox px={1} mr={2} mt={1} mb={1}>
            <MDAvatar {...stringAvatar(`${client.firstname} ${client.lastname}`)} alt="profile-image" size="l" shadow="sm" />
          </MDBox>
          <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <MDTypography variant="h6" fontWeight="medium" >
              {client.firstname} {client.lastname}
            </MDTypography>
            {/* <MDTypography variant="button" fontWeight="regular" color={"dark"}>
              {client.email}
            </MDTypography> */}
          </MDBox>
          <MDBox ml="auto" lineHeight={0} color={"white"}>
            <Link href="/clients/profil-client">

              <MDButton variant="text" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/clients/profil-client", { state: { clientId: client.id } })} >
                voir
          </MDButton>
            </Link>
          </MDBox>
          {/* <MDBox ml="auto">
          {action.type === "internal" ? (
            <MDButton component={Link} to={action.route} variant="text" color="info">
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color={action.color}
            >
              {action.label}
            </MDButton>
          )}
        </MDBox> */}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ClientCard;
