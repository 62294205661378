import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    updateProfile,
} from "firebase/auth";
import { auth } from "../config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../config";
import { updatePractitionersData } from "model/praticionersData";
import * as moment from 'moment'
import { getAllPractitionersData } from "model/praticionersData";

const UserContext = createContext();

// ======================AUTHENTIFICATION===================== //

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [practitionersData, setPractitionersData] = useState({});


    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };
    const statusIsTrial = (status, created) => {
        const now = moment()
        console.log(created);
        return now.isBetween(moment(created), moment(created).add(15, "days")) && status == "TRIAL"
    }

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const googleSignInMobile = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    const googleSignInDesktop = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
    };

    const logOut = () => {
        return signOut(auth);
    };

    const resetPwd = (email) => {
        return sendPasswordResetEmail(auth, email);
    };
    const getData = async (user) => {
        if (user) {
            const data = await getAllPractitionersData(user);


            setPractitionersData(data);
        }

        // // if (!data && !dataLoading) {
        //   updateDataIfGoogleSignIn(user);
        //   setGoogleUpdateLoading(false);
        // }
    };

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {

            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        getData(user);

    }, [user]);
    // ====================STORAGE===================== //

    const upload = async (file, user) => {
        const fileRef = ref(storage, `avatars/${user.uid}`);

        // setLoading(true);
        // eslint-disable-next-line no-unused-vars
        await uploadBytes(fileRef, file);
        const photoURL = await getDownloadURL(fileRef);
        await updateProfile(user, { photoURL: photoURL });

        await updatePractitionersData(user, { photoURL: photoURL });

        // setLoading(false);
    };

    return (
        <UserContext.Provider
            value={{
                createUser,
                user,
                practitionersData,
                statusIsTrial,
                logOut,
                signIn,
                resetPwd,
                googleSignInMobile,
                googleSignInDesktop,
                upload,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(UserContext);
};