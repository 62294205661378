/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import Header from "./components/Header";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDAvatar from "components/MDAvatar";
import { Icon, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import { useState, useEffect } from "react";
import { getAllPractitionersData } from "model/praticionersData";
import { useAuth } from "context/authContext";
import Link from '@mui/material/Link';

import Invoice from "layouts/billing/components/Invoice";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import * as moment from 'moment'
import { getAuth, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider, updateEmail } from "firebase/auth";
import '../../config.js'
import MDSnackbar from "components/MDSnackbar";
import { updatePractitionersData } from "model/praticionersData";
import md5 from "md5"
import { useNavigate } from "react-router-dom";


function Profil() {


  const auth = getAuth();
  const { user, statusIsTrial } = useAuth()


  const [practitionersData, setPractitionersData] = useState();
  const [datetrial, setDatetrial] = useState();
  const [renewalSub, setRenewalSub] = useState();
  const [password, setPassword] = useState();
  const [newEmail, setNewEmail] = useState();

  const [dataLoading, setDataLoading] = useState(true);
  const [successSB, setSuccessSB] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReAuthenticate, setOpenReAuthenticate] = useState(false);
  const [maxClient, setMaxClient] = useState("");
  const [maxGiga, setMaxGiga] = useState("");
  const navigate = useNavigate()
  const handleClickOpenReAuthenticate = () => {
    setOpenReAuthenticate(true);


  };
  const handleReAuthenticate = () => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      password
    );
    reauthenticateWithCredential(user, credential).then(() => {
      handleCloseReAuthenticate()
      handleClickOpen()
      // User re-authenticated  .
    }).catch((error) => {
      // An error ocurred
      // ...
    });
  }
  const handleCloseReAuthenticate = () => {
    setOpenReAuthenticate(false);
  };
  const handleClickOpen = () => {
    setOpen(true);



  };
  const handleUpdatePassword = async () => {
    updateEmail(auth.currentUser, newEmail).then(async () => {
      setPractitionersData({
        ...practitionersData,
        email: newEmail,
      })
      await updatePractitionersData(auth.currentUser, {
        ...practitionersData,
        email: newEmail,
      })
      setOpen(false)
      // Email updated!
      // ...
    }).catch((error) => {
      console.log(error);
      // An error occurred
      // ...
    });




  };
  const handleClose = () => {
    setOpen(false);
  };
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const getData = async (user) => {

    const data = await getAllPractitionersData(user);
    setPractitionersData(data);
    const now = moment()
    setDataLoading(false);
    // setRenewalSub(moment(practitionersData.subscription.current_period_end).format('L'))

    switch (data.status) {
      case 'FREE':
        setMaxClient("5")
        setMaxGiga("1")
        break;
      case 'STARTER':
        setMaxClient("20")
        setMaxGiga("5")
        break;
      case 'PRO':
        setMaxClient("50")
        setMaxGiga("10")
        break;
      case 'ENTERPRISE':
        setMaxClient("5000")

        setMaxGiga("50")
        break;
      default:
        setMaxClient("5")
        setMaxGiga("1")

    }

    // // if (!data && !dataLoading) {
    //   updateDataIfGoogleSignIn(user);
    //   setGoogleUpdateLoading(false);
    // }
  };

  useEffect(() => {
    getData(user);
  }, [user]);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="E-mail envoyé"
      content="E-mail de réinitialisation du mot de passe envoyé"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handlerResetPassword = () => {

    sendPasswordResetEmail(auth, practitionersData.email)
      .then(() => {

        openSuccessSB()


        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  const CheckoutForm = () => {
    return (
      <form>
        <PaymentElement />
        <button>Submit</button>
      </form>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* listen 443 ssl; # managed by Certbot
    ssl_certificate /etc/letsencrypt/live/zenego.pro/fullchain.pem; # managed by Certbot
    ssl_certificate_key /etc/letsencrypt/live/zenego.pro/privkey.pem; # managed by Certbot
    include /etc/letsencrypt/options-ssl-nginx.conf; # managed by Certbot
    ssl_dhparam /etc/letsencrypt/ssl-dhparams.pem; # managed by Certbot */}
      {/* {practitionersData && practitionersData.status == "TRIAL" && datetrial < 0 && <Alert variant="filled" severity="error" action={
        <MDButton color="inherit" size="small" href={"http://localhost:4000/create-checkout-session/" + user.uid + "/" + user.email}>
          s'abonner
    </MDButton>
      }>  Version d'essai terminé, abonnez-vous pour accéder aux fonctionnalitées </Alert>}
      {practitionersData && practitionersData.status == "CANCELED" && <Alert variant="filled" severity="error" action={
        <MDButton color="inherit" size="small" href={"https://billing.stripe.com/p/login/test_3csbIMakA2w16PedQQ"}>
          s'abonner
    </MDButton>
      }>  Abonnement terminé, rennouvelez votre abonnement pour accéder aux fonctionnalitées </Alert>} */}
      <Dialog open={openReAuthenticate} onClose={handleCloseReAuthenticate}>
        <DialogTitle>Ré-authentifier</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez-vous ré-authentifier pour pouvoir modifier votre adresse email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Mot de passe"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReAuthenticate}>Annuler</Button>
          <Button onClick={handleReAuthenticate}>envoyer</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modification email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez renseigner votre nouvelle adresse email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setNewEmail(e.target.value)}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleUpdatePassword}>Modifier</Button>
        </DialogActions>
      </Dialog>
      {practitionersData && <MDBox py={3}>


        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ height: "100%" }}>
              <Grid container spacing={3} alignItems="center" >

                {user && <Grid item m={2}  >
                  <MDAvatar src={user.photoURL != null ? user.photoURL : "https://zenego.s3.eu-west-1.amazonaws.com/avatar.png"} alt="profile-image" size="xl" shadow="sm" />
                </Grid>}
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {practitionersData.firstname} {practitionersData.lastname}
                    </MDTypography>
                    {/* <MDTypography variant="button" color="text" fontWeight="regular">
                      Sophrologue
              </MDTypography> */}
                  </MDBox>

                </Grid>
                <Grid item>

                  <MDButton variant="text" color="primary" href="/profil/edit">
                    <Icon>edit</Icon>editer profil
            </MDButton>

                </Grid>
              </Grid>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                {/* <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography> */}
                {/* <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography> */}
              </MDBox>
              <MDBox p={2}>
                {/* <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox> */}
                <MDBox opacity={0.3}>
                </MDBox>
                <MDBox>
                  <MDTypography mb={2} variant="h6" fontWeight="medium">
                    Information
        </MDTypography>

                  <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                      pr={0.5}
                    >
                      Téléphone:
          </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {practitionersData.phone}
                    </MDTypography>

                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                      pr={0.5}
                    >
                      Adresse:
          </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {practitionersData.adress}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                      pr={0.5}
                    >
                      Code Postal:
          </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {practitionersData.postCode}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                      pr={0.5}
                    >
                      Ville:
          </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {practitionersData.city}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                      pr={0.5}
                    >
                      Site web:
          </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {practitionersData.website_url}
                    </MDTypography>

                  </MDBox>

                </MDBox>

              </MDBox>

            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Card>
                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium">
                        Plan actuel
                </MDTypography>

                    </MDBox>
                    <MDBox display="flex" py={1} px={2} lineHeight={1}>
                      {/* {practitionersData.status == "TRIAL" && <MDTypography variant="button" color="dark" textTransform="capitalize"
                        pr={0.5}
                      >
                        Version d'essai ( {datetrial < 0 ? "terminé" : datetrial + " jours restant"}  )
                      </MDTypography>} */}
                      {practitionersData.status && maxClient && <MDBox>
                        <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                          <MDTypography variant="button" color="dark" textTransform="capitalize"
                            pr={0.5}
                          >
                            Version {practitionersData.status}
                          </MDTypography>

                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                          <MDTypography variant="button" color="dark"
                            pr={0.5}
                          >
                            {practitionersData.status != "ENTERPRISE" ? `- Limité à ${maxClient} clients` : `- Clients ilimités`}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" py={1} pr={2} lineHeight={1}>

                          <MDTypography variant="button" color="dark"
                            pr={0.5}
                          >
                            - Limité à {maxGiga}GB de stockage
                          </MDTypography>
                        </MDBox>


                      </MDBox>}


                    </MDBox>
                  </Grid>
                  {practitionersData.status == "FREE" && <Grid item xs={12} md={6} lg={6}>
                    <MDBox display="flex"
                      justifyContent="center"
                      alignItems="center"
                      pt={2}
                      pb={2}>

                      {/* <MDButton color="info" href={"http://localhost:4000/create-checkout-session/" + user.uid + "/" + user.email}>
                        s'abonner
            </MDButton> */}
                      <MDButton color="info" onClick={() => navigate("/paywall", { state: { customerId: practitionersData.id, customerEmail: practitionersData.email } })}>
                        s'abonner
            </MDButton>
                    </MDBox>

                  </Grid>}

                  {practitionersData.status != "FREE" && <Grid item xs={12} md={6} lg={6}>
                    <MDBox display="flex"
                      justifyContent="center"
                      alignItems="center"
                      pt={2}
                      pb={2}>

                      <MDButton color="info" href={`${process.env.REACT_APP_PORTAL_BILLING}`}>
                        gérer mon abonnement
            </MDButton>

                    </MDBox>

                  </Grid>}

                </Grid>
              </Card>

              {/* <Card>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>

              </Card> */}
            </MDBox>
            {/* <MDBox mb={1.5}>
              {practitionersData.status != "TRIAL" && <PaymentMethod />} */}
            {/* <Card>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>

              </Card> */}
            {/* </MDBox>

            <MDBox mb={1.5}>

              {practitionersData.status != "TRIAL" && < Invoices />}
            </MDBox> */}

          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Card>
                <Grid container>
                  <Grid item xs={12} >
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                      <Grid item  >
                        <MDTypography variant="h6" fontWeight="medium">
                          Identifiants
                </MDTypography>
                      </Grid>
                      <Grid item  >
                        <MDButton variant="text" color="primary" onClick={handleClickOpenReAuthenticate}>
                          <Icon>edit</Icon>modifier email
            </MDButton>
                      </Grid>
                    </MDBox>
                    <MDBox display="flex" mt={2} px={2} py={1} pr={2} lineHeight={1}>

                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                        pr={0.5}
                      >
                        Email:
</MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {practitionersData.email}
                      </MDTypography>

                    </MDBox>

                    <MDBox display="flex" px={2} py={1} pr={2} lineHeight={1}>

                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                        pr={0.5}
                      >
                        Mot de passe:
</MDTypography>
                      <Link
                        component="button"
                        underline="always"
                        onClick={handlerResetPassword}
                      >
                        Réinitialiser mot de passe
                      </Link>
                      {renderSuccessSB}
                    </MDBox>

                  </Grid>





                </Grid>
              </Card>
              {/* <Card>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>

              </Card> */}
            </MDBox>

          </Grid>

        </Grid>
      </MDBox>}

      <Footer />
    </DashboardLayout >
  );
}

export default Profil;
