/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { Link } from "@mui/material";
import { getSeancesList } from "model/seances";
import { useAuth } from "context/authContext";
import { backgroundColor } from "colorszenego";
import { useNavigate } from "react-router-dom";

export default function data() {
  const { user } = useAuth();
  const [seanceList, setSeanceList] = useState([]);
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "min" : "min") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + " " + mDisplay + " " + sDisplay;
  }
  const getList = async (user) => {
    try {
      const list = await getSeancesList(user);

      if (list) {
        setSeanceList(list);

      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    getList(user);

  }, [user])


  const Titre = ({ title }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1} textTransform="capitalize">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );
  if (isMobile) {
    return {
      columns: [
        { Header: "titre", accessor: "titre", align: "left" },

        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: seanceList.map((seance) => {
        return {

          titre: seance.title,
          action: (

            <MDButton variant="gradient" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/seances/profil-seance", { state: { seanceId: seance.id } })} >
              voir
            </MDButton>
          ),

        }
      })
    };
  } else {
    return {
      columns: [
        { Header: "titre", accessor: "titre", align: "left" },
        { Header: "methode", accessor: "methode", align: "left" },
        { Header: "thematique", accessor: "thematique", align: "center" },
        // { Header: "type", accessor: "type", align: "center" },
        { Header: "time", accessor: "time", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: seanceList.map((seance) => {
        return {

          titre: seance.title,
          methode: seance.method,
          thematique: seance.thematic,
          // type: seance.media_type,
          // (
          //   <MDBox borderRadius="lg" bgColor={backgroundColor(seance.thematic)} >
          //     <MDTypography p={1} variant="button" fontWeight="bold" color={"white"} textTransform="capitalize">
          //       {seance.thematic}
          //     </MDTypography>
          //   </MDBox>
          // ),
          time: secondsToHms(seance.media_duration),
          //   <MDTypography display="block" variant="button" fontWeight="regular">
          //     15 min
          // </MDTypography>,
          action: (

            <MDButton variant="gradient" color="primary" startIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} onClick={() => navigate("/seances/profil-seance", { state: { seanceId: seance.id } })} >
              voir
            </MDButton>
          ),

        }
      })
    };
  }

}
