/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControl from '@mui/material/FormControl';

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { InputLabel, Button, Icon, Grid, FormHelperText, Box, Popover, } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import createSeanceContext from "context/createSeanceContext";
import { useContext, useRef, useEffect, useState } from "react";
import { useAuth } from "context/authContext";
import { createSeance } from "model/seances";
import { postSeanceMedia } from "model/seances";
import { updateSeance } from "model/seances";
import ReactPlayer from "react-player";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from "react-drag-drop-files";
import { uploadBytesResumable, ref as refStorage, getDownloadURL, } from "firebase/storage";
import { db, storage } from "config";
import MDProgress from "components/MDProgress";
import { getAllPractitionersData } from "model/praticionersData";
// import { getAnalytics, logEvent } from "firebase/analytics";
function CoverSeance() {
  const { user } = useAuth();
  const navigate = useNavigate()
  const [mediaName, setMediaName] = useState();
  const [practitionersData, setPractitionersData] = useState();
  const [maxGiga, setMaxGiga] = useState(0);
  const [overStockage, setOverStockage] = useState(false);

  const [file, setFile] = useState(null);
  const [noFile, setNoFile] = useState(false);

  const [loader, setLoader] = useState(false);
  const [labelDrop, setLabelDrop] = useState("Téléchargez ou déposez un fichier ici");

  const [progressUpload, setProgressUpload] = useState(0);
  const fileTypes = ["MP3", "MP4", "MOV", "PDF", "JPG", "PNG", "M4A", "WAV", "M4V"];

  const { seanceData, setSeanceData, setMedia,
    media,
    setCompletedStep,
    urlSource,
    setUrlSource, } =
    useContext(createSeanceContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? 'simple-popover' : undefined;
  const getData = async (user) => {
    const data = await getAllPractitionersData(user);
    setPractitionersData(data);
    switch (data.status) {
      case 'FREE':
        setMaxGiga(1)
        break;
      case 'STARTER':
        setMaxGiga(5)
        break;
      case 'PRO':
        setMaxGiga(10)

        break;
      case 'ENTERPRISE':
        setMaxGiga(50)

        break;
      default:
        setMaxGiga(1)

    }
  }
  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Ce champ est obligatoire"),
    method: Yup.string()
      .required("Vous devez choisir une thématique"),
    thematic: Yup.string()
      .required("Vous devez choisir une thématique"),
    description: Yup.string(),
    // confirmPassword: Yup.string()
    //   .required("Confirmation de mot de passe est obligatoire")
    //   .oneOf(
    //     [Yup.ref("password"), null],
    //     "Le mot de passe de confirmation ne correspond pas"
    //   ),
    // acceptTerms: Yup.bool().oneOf([true], "Accepter les conditions est obligatoire"),
  });
  const initialValues = {
    title: "",
    method: "",
    thematic: "",
    description: "",

    // acceptTerms: false,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)

    },
  });
  const handleChange = (file) => {
    setLabelDrop("Fichier validé")
    setFile(file);
    setMedia(file);
    setNoFile(false)
    let mediaSize = file.size
    let mediaTotalSize = mediaSize + practitionersData.seance_size


    if ((mediaTotalSize / (1000 * 1000 * 1000)).toFixed(3) > maxGiga) {
      setOverStockage(true)
    } else {
      setOverStockage(false)
    }
    setMediaName(file.name)

    setSeanceData({
      ...seanceData,
      media_type: file.type,
      media_name: file.name,
      media_size: file.size,
    });

  };

  const handleFile = () => {
    if (
      fileInput.current.files[0].type.indexOf("audio") !== -1 ||
      fileInput.current.files[0].type.indexOf("video") !== -1
    ) {

      setMedia(fileInput.current.files[0]);

      setMediaName(fileInput.current.files[0].name)

      setSeanceData({
        ...seanceData,
        media_name: fileInput.current.files[0].name,
        media_size: fileInput.current.files[0].size,
      });

    }
  };
  const handleMediaDuration = (duration) => {

    setSeanceData({
      ...seanceData,
      media_duration: Math.ceil(duration),
    });
  };
  const handleSubmit = async (values) => {
    if (file) {
      try {
        setLoader(true);
        const seanceID = await createSeance(user, { ...seanceData, ...values });


        const media_url = await postSeanceMedia(
          user,
          media,
          seanceID,
          `${seanceData.media_name}`
        );
        updateSeance(user, seanceID, {
          media_url,
        });
        const storageRef = refStorage(
          storage,
          `practitioners/${user.uid}/seances/${seanceID}/${seanceData.media_name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressUpload(Math.round(progress))
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //   console.log('File available at', downloadURL);
            // });
            // const analytics = getAnalytics();
            // logEvent(analytics, 'add_seance');
            navigate("/seances/profil-seance", { state: { seanceId: seanceID } });

            setSeanceData({});
          })

      } catch (error) {
        console.error(error);
        warn(`Une erreur est survenue : ${error}`);
        navigate("/seances");
      }
    } else {
      setNoFile(true)
    }
    // finally {
    //   setIsLoading(false);
    // }
  };
  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onload = () => setUrlSource(fileReader.result);

    if (seanceData.media_name) setCompletedStep(true);
    if (media.type) fileReader.readAsDataURL(media);
  }, [seanceData, setCompletedStep, media]);
  useEffect(() => {
    getData(user);

  }, [user]);
  return (

    <DashboardLayout>

      <DashboardNavbar />
      <Grid container >
        <Grid lg={2}></Grid>
        <Grid sm={12} lg={7} >

          <MDBox pt={6} pb={3} >
            <Card >
              <MDBox
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                  Nouvelle Séance
          </MDTypography>
                <MDTypography display="block" variant="button" color="dark" my={1}>
                  Ajoutez les informations de votre séance
          </MDTypography>
              </MDBox>
              {loader === true ? <MDBox pt={4} pb={3} px={3} >

                <MDTypography display="block" variant="button" fontWeight="bold" color="dark" >
                  Stockage de la séance en cours ...
                      </MDTypography>
                <MDProgress label value={progressUpload} />
              </MDBox> :
                <MDBox pt={4} pb={3} px={3} >


                  <MDBox>
                    <MDBox mt={4} mb={1} textAlign="center" >

                      <FileUploader handleChange={handleChange} name="file" label={labelDrop} types={fileTypes} />
                      {noFile && <MDBox mt={4} mb={1} textAlign="center">
                        <MDTypography variant="button" justifyContent="center" fontWeight="bold" color="error">
                          Veuillez télécharger ou déposer un fichier ! </MDTypography>

                      </MDBox>}
                      {mediaName && <MDBox mt={4} mb={1} textAlign="center">
                        <MDTypography display="block" variant="button" fontWeight="bold" color="dark" >
                          {mediaName}
                        </MDTypography>

                      </MDBox>}


                      {overStockage && <MDBox justifyContent="space-between" alignItems="center" pt={2} px={2} style={{ backgroundColor: "white" }}>
                        <MDBox display="flex" lineHeight={1} justifyContent="center">
                          <MDTypography variant="button" justifyContent="center" fontWeight="bold" color="error">
                            Fichier trop volumineux. </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="center" lineHeight={1}>

                          <MDTypography variant="button" mb={2} fontWeight="bold" color="error">
                            Améliorez votre plan pour augmenter votre stockage.</MDTypography>

                        </MDBox>
                        <MDBox display="flex" justifyContent="center" lineHeight={1}>
                          <MDButton aria-describedby={id} pt={2} onClick={() => navigate("/profil")} variant="gradient" color="secondary" endIcon={<Icon fontSize="small">rocket</Icon>}>
                            Améliorer
</MDButton>
                        </MDBox>
                      </MDBox>}


                    </MDBox>
                    <form onSubmit={formik.handleSubmit}>

                      <MDBox mb={2}>
                        <MDInput type="text" label="Titre" variant="standard" name="title"
                          fullWidth
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                          helperText={formik.touched.title && formik.errors.title} />
                      </MDBox>

                      <MDBox mb={4}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-label" error={formik.touched.thematic && Boolean(formik.errors.thematic)}>Thématique</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Thématique"
                            name="thematic"
                            value={formik.values.thematic}
                            onChange={formik.handleChange}
                            error={formik.touched.thematic && Boolean(formik.errors.thematic)}
                          >
                            <MenuItem value="découverte">Découverte</MenuItem>
                            <MenuItem value="sommeil">Sommeil</MenuItem>
                            <MenuItem value="énergie">Energie</MenuItem>
                            <MenuItem value="grossesse">Grossesse</MenuItem>
                            <MenuItem value="stress">Stress</MenuItem>
                            <MenuItem value="enfants">Enfant</MenuItem>
                            <MenuItem value="émotions">Emotion</MenuItem>
                            <MenuItem value="mental">Mental</MenuItem>
                            <MenuItem value="autre">Autre</MenuItem>
                          </Select>
                          {formik.touched.thematic && formik.errors.thematic && <FormHelperText>{formik.errors.thematic}</FormHelperText>}
                        </FormControl>

                      </MDBox>
                      <MDBox mb={4}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-label" error={formik.touched.method && Boolean(formik.errors.method)}>Méthode</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Thématique"
                            name="method"
                            value={formik.values.method}
                            onChange={formik.handleChange}
                            error={formik.touched.method && Boolean(formik.errors.method)}
                          >
                            <MenuItem value="sophrologie">Sophrologie</MenuItem>
                            <MenuItem value="yoga">Yoga</MenuItem>
                            <MenuItem value="méditation">Méditation</MenuItem>
                            <MenuItem value="cohérence cardiaque">Cohérence cardiaque</MenuItem>
                            <MenuItem value="voyage sonore">Voyage sonore</MenuItem>
                            <MenuItem value="autre">Autre</MenuItem>
                          </Select>
                          {formik.touched.method && formik.errors.method && <FormHelperText>{formik.errors.method}</FormHelperText>}
                        </FormControl>

                      </MDBox>


                      <MDBox mb={2}>
                        <MDInput type="" label="Description" multiline rows={5} name="description"
                          fullWidth
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description} />
                      </MDBox>


                      <MDBox mt={4} mb={1} textAlign="center">
                        <MDButton disabled={overStockage} variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} type="submit">
                          Ajouter Séance
              </MDButton>
                      </MDBox>
                    </form>

                  </MDBox>
                </MDBox>}
            </Card>
          </MDBox>
        </Grid>

      </Grid>
    </DashboardLayout>
  );
}

export default CoverSeance;
