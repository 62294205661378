/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControl from '@mui/material/FormControl';

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { InputLabel, Button, Icon, Grid, FormHelperText } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import createClientContext from "context/createClientContext";
import { useContext } from "react";
import { useAuth } from "context/authContext";
import { createClient } from "model/client";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { getAnalytics, logEvent } from "firebase/analytics";

function CoverClient() {
  const navigate = useNavigate()
  const { clientData, setClientData, validation, setValidation } =
    useContext(createClientContext);
  const { user } = useAuth();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("Ce champ est obligatoire"),
    lastname: Yup.string()
      .required("Ce champ est obligatoire"),
    email: Yup.string()
      .email("e-mail invalide")
      .required("l'e-mail est obligatoire"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'téléphone invalide'),
    streetName: Yup.string()
    ,
    postalCode: Yup.number()
      .min(5, "le code postal est trop court"),
    city: Yup.string(),
    country: Yup.string(),
    thematic: Yup.string()
      .required("Vous devez choisir une thématique"),
    motif: Yup.string(),
    // confirmPassword: Yup.string()
    //   .required("Confirmation de mot de passe est obligatoire")
    //   .oneOf(
    //     [Yup.ref("password"), null],
    //     "Le mot de passe de confirmation ne correspond pas"
    //   ),
    // acceptTerms: Yup.bool().oneOf([true], "Accepter les conditions est obligatoire"),
  });
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    streetName: "",
    postalCode: "",
    city: "",
    country: "",
    thematic: "",
    motif: "",

    // acceptTerms: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)

    },
  });
  const handleSubmit = async (values) => {
    const clientId = await createClient(user, values);
    // const analytics = getAnalytics();
    // logEvent(analytics, 'add_seance');
    navigate("/clients/profil-client", { state: { clientId: clientId } })
    setClientData({});

    setTimeout(2000);

  };

  return (

    <DashboardLayout>

      <DashboardNavbar />
      <Grid container >
        <Grid lg={2}></Grid>
        <Grid sm={12} lg={7} >

          <MDBox pt={6} pb={3} >
            <Card >
              <MDBox
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                  Nouveau client
          </MDTypography>
                <MDTypography display="block" variant="button" color="dark" my={1}>
                  Ajoutez les informations de votre client
          </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3} >
                <MDBox >
                  <form onSubmit={formik.handleSubmit}>
                    {/* <MDBox mt={4} mb={1} textAlign="center" >
                    <MDButton variant="gradient" color="secondary" component="label" endIcon={<Icon fontSize="small">photo</Icon>} >
                      Ajouter photo
                <input hidden accept="image/*" multiple type="file" />

                    </MDButton>
                  </MDBox> */}

                    <MDBox mb={2}>
                      <MDInput type="text" label="Nom" variant="standard"
                        // value={clientData.lastname || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       lastname: e.target.value,
                        //     })
                        //   } 
                        name="lastname"
                        fullWidth
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                        helperText={formik.touched.lastname && formik.errors.lastname}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="text" label="Prénom" variant="standard"
                        // value={clientData.firstname || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       firstname: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="firstname"
                        fullWidth
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                        helperText={formik.touched.firstname && formik.errors.firstname}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="email" label="E-mail" variant="standard"
                        // value={clientData.email || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       email: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="email"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="phone" label="Téléphone" variant="standard"
                        // value={clientData.phoneNumber || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       phoneNumber: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="phoneNumber"
                        fullWidth
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      />

                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="adress" label="Adresse" variant="standard"
                        // value={clientData.streetName || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       streetName: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="streetName"
                        fullWidth
                        value={formik.values.streetName}
                        onChange={formik.handleChange}
                        error={formik.touched.streetName && Boolean(formik.errors.streetName)}
                        helperText={formik.touched.streetName && formik.errors.streetName}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="postCode" label="Code postal" variant="standard"
                        // value={clientData.postalCode || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       postalCode: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="postalCode"
                        fullWidth
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="city" label="Ville" variant="standard"
                        // value={clientData.city || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       city: e.target.value,
                        //     })
                        //   } fullWidth
                        name="city"
                        fullWidth
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput type="country" label="Pays" variant="standard"
                        // value={clientData.country || ""}
                        //   onChange={(e) =>
                        //     setClientData({
                        //       ...clientData,
                        //       country: e.target.value,
                        //     })
                        //   } fullWidth 
                        name="country"
                        fullWidth
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                      />
                    </MDBox>
                    <MDBox mb={4}>
                      <FormControl variant="standard" fullWidth name="thematic"
                      >
                        <InputLabel id="demo-simple-select-label" error={formik.touched.thematic && Boolean(formik.errors.thematic)}>Thématique</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Thématique"
                          // value={clientData.thematic || ""}
                          // onChange={(e) =>
                          //   setClientData({
                          //     ...clientData,
                          //     thematic: e.target.value,
                          //   })
                          // }
                          name="thematic"
                          value={formik.values.thematic}
                          onChange={formik.handleChange}
                          error={formik.touched.thematic && Boolean(formik.errors.thematic)}
                        >
                          <MenuItem value="découverte">Découverte</MenuItem>
                          <MenuItem value="sommeil">Sommeil</MenuItem>
                          <MenuItem value="énergie">Energie</MenuItem>
                          <MenuItem value="grossesse">Grossesse</MenuItem>
                          <MenuItem value="stress">Stress</MenuItem>
                          <MenuItem value="enfants">Enfant</MenuItem>
                          <MenuItem value="émotion">Emotion</MenuItem>
                          <MenuItem value="mental">Mental</MenuItem>
                          <MenuItem value="autre">Autre</MenuItem>
                        </Select>
                        {formik.touched.thematic && formik.errors.thematic && <FormHelperText>{formik.errors.thematic}</FormHelperText>}

                      </FormControl>

                    </MDBox>


                    <MDBox mb={2}>
                      <MDInput type="" label="Note" multiline rows={5}
                        // value={clientData?.motif}
                        //   onChange={(e) =>
                        //     setClientData({ ...clientData, motif: e.target.value })
                        //   } fullWidth 
                        name="motif"
                        fullWidth
                        value={formik.values.motif}
                        onChange={formik.handleChange}
                        error={formik.touched.motif && Boolean(formik.errors.motif)}
                        helperText={formik.touched.motif && formik.errors.motif}
                      />
                    </MDBox>


                    <MDBox mt={4} mb={1} textAlign="center">
                      <MDButton variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} type="submit">
                        Ajouter Client
              </MDButton>
                    </MDBox>
                  </form>
                </MDBox>

              </MDBox>
            </Card>
          </MDBox>
        </Grid>

      </Grid>
    </DashboardLayout>
  );
}

export default CoverClient;
