/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDAvatar from "components/MDAvatar";
import { Icon, Modal, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, Chip, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import ClientCard from "./components/ClientCard";
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'
import { useAuth } from "context/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getSeanceData } from "model/seances";
import { getSeanceMediaUrl } from "model/seances";
import { backgroundColor } from "colorszenego";
import { getClientList } from "model/client";
import { updateSeance } from "model/seances";
import { getClientData } from "model/client";
import { updateClient } from "model/client";
import { deleteSeanceMedia } from "model/seances";
import { deleteSeance } from "model/seances";
import ImgsViewer from "react-images-viewer";
import { PDFReader } from 'react-read-pdf';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "min" : "min") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

function ProfileSeance() {
    const location = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [deleteInput, setDeleteInput] = useState("");
    const [seanceData, setSeanceData] = useState();
    const [urlSource, setUrlSource] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [selectedClientList, setSelectedClientList] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [openImgViewer, setOpenImgViewer] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const { user } = useAuth();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleClickOpenImg = () => {
        setOpenImgViewer(true);
    };

    const handleClickCloseImg = () => {
        setOpenImgViewer(false);
    };
    const getSelectedClientList = async (clientListId) => {


        let datas = []
        for (let item of clientListId) {
            let data = await getClientData(user, item)
            if (data) {
                data = { ...data, key: data.id }
                datas.push(data)
            }

        }

        setSelectedClientList(datas)


    }
    const handleDelete = () => {
        deleteSeanceMedia(seanceData.media_url);
        deleteSeance(user, location.state.seanceId);
        handleCloseDelete()
        navigate("/seances")

    };
    const getData = async (user, id) => {
        const sData = await getSeanceData(user, id);
        setSeanceData(sData);
        if (sData) {
            const url = await getSeanceMediaUrl(sData.media_url);
            console.log("url: ", url)
            setUrlSource(url);
            if (sData.clientList) {
                getSelectedClientList(sData.clientList)
                // const tab = sData.clientList.map((e) => {
                //     return {
                //         ...e, key: e.id
                //     }
                // })
                // setSelectedClientList(tab)
            }
        }
        setLoadingData(false);
    };
    const handleSubmit = async () => {

        const selectedClientIDList = selectedClientList.map((client) => client.id);
        updateSeance(user, seanceData.id, { clientList: selectedClientIDList });


        const currentClientIDList = clientList.map((client) => client.id);

        const removeClientIDList = [];

        currentClientIDList.forEach((clientID) => {
            if (!selectedClientIDList.includes(clientID))
                removeClientIDList.push(clientID);
        });

        for (const clientID of selectedClientIDList) {
            const clientData = await getClientData(user, clientID);
            let newSeanceList = [seanceData.id];
            if (clientData.seanceList && !clientData.seanceList?.includes(seanceData.id))
                newSeanceList = [...clientData.seanceList, seanceData.id];
            if (clientData.seanceList && clientData.seanceList?.includes(seanceData.id))
                newSeanceList = clientData.seanceList;
            updateClient(user, clientID, {
                seanceList: newSeanceList,
            });
        }

        for (const clientID of removeClientIDList) {
            const clientData = await getClientData(user, clientID);
            const index = clientData.seanceList?.indexOf(seanceData.id);
            if (clientData.seanceList && clientData.seanceList?.includes(seanceData.id)) {
                delete clientData.seanceList[index];
                updateClient(user, clientID, {
                    seanceList: clientData.seanceList,
                });
            }
        }
        getData(user, location.state.seanceId);
        handleClose()
    };
    const handleMediaDuration = (duration) => {

        if (!seanceData.media_duration || seanceData.media_duration != duration) {

            updateSeance(user, seanceData.id, {
                media_duration: Math.round(duration),
            });
            setSeanceData({
                ...seanceData,
                media_duration: Math.round(duration),
            });
        }

    };
    const handleChange = (e) => {
        const value = e.target.value;

        if (selectedClientList.includes(e.target.value) === false)
            setSelectedClientList(
                typeof value === "string" ? value.split(",") : value
            );
    };

    useEffect(() => {
        getClientList(user)
            .then((res) => {


                const tab = res.map((e) => {
                    return {
                        ...e, key: e.id
                    }
                })
                setClientList(tab);

            })
            .then(() => setIsLoading(false));

    }, [isLoading, user]);

    useEffect(() => {
        getData(user, location.state.seanceId);


    }, [location.state.seanceId, user]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Supression de la séance"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer la séance?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton variant="text" color="error" onClick={handleDelete}>supprimer</MDButton>
                    <MDButton onClick={handleCloseDelete} >
                        Annuler
          </MDButton>
                </DialogActions>
            </Dialog>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox sx={style}>
                    <Card >
                        {clientList.length > 0 ? <MDBox pt={4} pb={3} px={3}  >

                            <MDBox component="form" role="form">
                                <MDBox mt={2}>
                                    <MDTypography mb={2} variant="h6" fontWeight="medium" textAlign="center">
                                        Séléctionner les clients à associer :
                                </MDTypography>
                                </MDBox>

                                {seanceData && <FormControl variant="standard" fullWidth >
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={clientList}
                                        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}

                                        defaultValue={selectedClientList}
                                        value={selectedClientList}

                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, newInputValue) => {

                                            setSelectedClientList(newInputValue);

                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Clients associés"
                                                placeholder="Ajouter client"
                                            />
                                        )}

                                    />
                                </FormControl>}


                            </MDBox>
                            <MDBox mt={3} text textAlign="center">
                                <MDButton variant="gradient" color="success" onClick={() => handleSubmit()} >
                                    Modifier
</MDButton>
                            </MDBox>

                        </MDBox> :
                            <MDBox pt={4} pb={3} px={3} >
                                <MDBox textAlign="center" lineHeight={1.25} pb={3}>
                                    <MDTypography variant="h6" fontWeight="medium" textAlign="center">Pas encore de client !</MDTypography>

                                </MDBox>
                                <MDBox textAlign="center" lineHeight={1.25}>
                                    <MDButton variant="gradient" color="success" onClick={() => { navigate("/clients/nouveau-client") }} endIcon={<Icon fontSize="small">add</Icon>} >
                                        Ajouter Client
                  </MDButton>
                                </MDBox>
                            </MDBox>}
                    </Card>
                </MDBox>
            </Modal>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        {seanceData && <Card sx={{ height: "100%" }}>
                            <MDBox height="100%" m={2} lineHeight={1} textAlign="center">
                                <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                                    {seanceData.title}
                                </MDTypography>

                            </MDBox>


                            <MDBox height="100%" m={2} lineHeight={1} >

                                <MDButton m={2} variant="gradient" color="primary" onClick={() => navigate("/seances/profil-seance/edit", { state: { seanceId: seanceData.id } })} startIcon={<Icon fontSize="small">edit</Icon>}>
                                    editer séance
                                </MDButton>
                            </MDBox>

                            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>

                            </MDBox>
                            <MDBox p={2}>

                                <MDBox opacity={0.3}>
                                </MDBox>
                                <MDBox>
                                    <MDTypography mb={2} variant="h6" fontWeight="medium">
                                        Informations
                                </MDTypography>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Fichier:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {seanceData.media_name}
                                        </MDTypography>

                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>

                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Thématique:
                                    </MDTypography>
                                        <MDBox borderRadius="lg" bgColor={backgroundColor(seanceData.thematic)}>
                                            <MDTypography px={2} variant="button" fontWeight="bold" color={"white"} textTransform="capitalize">
                                                {seanceData.thematic}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Méthode:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                                            {seanceData.method}
                                        </MDTypography>


                                    </MDBox>

                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Temps:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {secondsToHms(seanceData.media_duration)}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            Création:
                                    </MDTypography>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {new Date(seanceData.creation_date).toLocaleDateString('fr-FR')}
                                        </MDTypography>
                                    </MDBox>

                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"
                                            pr={0.5}
                                        >
                                            description:
                                    </MDTypography>
                                    </MDBox>
                                    <MDBox lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {seanceData.description}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display="flex" py={1} pr={2} lineHeight={1}>
                                        {urlSource && urlSource.match('.png|.jpg') && <MDButton variant="gradient" color="secondary" onClick={() => { handleClickOpenImg() }} endIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} >
                                            voir
                  </MDButton>}
                                        {urlSource && urlSource.match('.pdf') && <MDButton variant="gradient" color="secondary" onClick={() => { window.open(urlSource, '_blank'); }} endIcon={<Icon fontSize="small">remove_red_eye_icon</Icon>} >
                                            voir
                  </MDButton>}
                                    </MDBox>
                                </MDBox>
                                <MDBox display="flex">

                                    {urlSource && urlSource.match('.mp4|.mov|.mp3|.m4a|.wav|.m4v') && <ReactPlayer controls
                                        onDuration={(e) => handleMediaDuration(e)}
                                        height={100}
                                        // url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}
                                        url={urlSource}

                                    />}
                                    {urlSource && urlSource.match('.png|.jpg') && <ImgsViewer
                                        imgs={[
                                            { src: urlSource },
                                        ]}
                                        isOpen={openImgViewer}
                                        onClose={() => handleClickCloseImg()}
                                    />}
                                    {/* https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3 */}
                                </MDBox>
                            </MDBox>

                            <MDButton variant="text" color="error" onClick={handleClickOpenDelete}>
                                <Icon>delete</Icon>supprimer séance
                                </MDButton>
                        </Card>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>

                        <MDBox mb={1.5}>
                            {seanceData && <Card sx={{ boxShadow: "none" }}>

                                <MDBox mb={2} pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Client(s) associé(s)
        </MDTypography>
                                    <MDButton variant="gradient" color="success" onClick={handleOpen} >
                                        Modifier Assiociation
</MDButton>
                                    {/* <MDButton variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton> */}
                                </MDBox>
                                {seanceData.clientList &&
                                    seanceData.clientList?.map((clientId) => (
                                        <ClientCard clientId={clientId} ></ClientCard>
                                    ))}


                            </Card>}

                        </MDBox>

                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout >
    );
}

export default ProfileSeance;
