/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { backgroundColor } from "colorszenego";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
function DataTableBodyCell({ noBorder, align, children }) {

  if (children.props.column.Header == "thematique") {
    return (<MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { dark }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${dark.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        <MDBox borderRadius="lg" bgColor={backgroundColor(children.props.cell.value)}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            <MDTypography p={1} variant="button" fontWeight="bold" color={"white"} textTransform="capitalize" >
              {children}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>)
  } else if (children.props.column.Header == "numéro") {
    return (<MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { dark }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${dark.main}`,
      })}
    >

      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        <MDTypography display="block" variant="button" fontWeight="medium">

          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {children}
          </MDTypography>
        </MDTypography>

      </MDBox>
    </MDBox>)
  } else if (children.props.column.Header == "client") {
    return (
      <MDBox
        component="td"
        textAlign={align}
        py={1.5}
        px={3}
        sx={({ palette: { dark }, typography: { size }, borders: { borderWidth } }) => ({
          fontSize: size.sm,
          borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${dark.main}`,
        })}
      >

        <MDBox
          display="inline-block"
          width="max-content"
          color="text"
          sx={{ verticalAlign: "middle" }}
        >
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar {...stringAvatar(`${children.props.cell.value}`)} size="sm" />
            <MDBox ml={2} lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {children}
              </MDTypography>

            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    );
  } else if (children.props.column.Header == "email") {
    return (
      <MDBox
        component="td"
        textAlign={align}
        py={1.5}
        px={3}
        sx={({ palette: { dark }, typography: { size }, borders: { borderWidth } }) => ({
          fontSize: size.sm,
          borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${dark.main}`,
        })}
      >
        <MDBox
          display="inline-block"
          width="max-content"
          color="text"
          sx={{ verticalAlign: "middle" }}
        >
          <MDTypography display="block" variant="button" fontWeight="medium" >
            {children}
          </MDTypography>
        </MDBox>
      </MDBox >
    );
  }
  else {
    return (
      <MDBox
        component="td"
        textAlign={align}
        py={1.5}
        px={3}
        sx={({ palette: { dark }, typography: { size }, borders: { borderWidth } }) => ({
          fontSize: size.sm,
          borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${dark.main}`,
        })}
      >
        <MDBox
          display="inline-block"
          width="max-content"
          color="text"
          sx={{ verticalAlign: "middle" }}
        >
          <MDTypography display="block" variant="button" fontWeight="medium" textTransform="capitalize">
            {children}
          </MDTypography>
        </MDBox>
      </MDBox >
    );
  }

}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
