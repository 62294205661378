/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/backpro.jpg";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import '../../../config.js'
import { useNavigate } from "react-router-dom";
//navigate("/dashboard");

const auth = getAuth();


function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const auth = getAuth();
  const navigate = useNavigate();
  const sigin = () => {
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log(user);
      localStorage.setItem("user", JSON.stringify(user))

      navigate("/dashboard");
      // ...
    })
      .catch((error) => {
        console.log(error.message);
        const errorCode = error.code;

        let errorMessage = ""
        switch (errorCode) {
          case 'auth/invalid-email':
            errorMessage = "Mauvais e-mail"
            break;
          case 'auth/network-request-failed':
            errorMessage = "Pas de connexion Internet"
            break;
          case 'auth/wrong-password':
            errorMessage = "Mauvais mot de passe"
            break;
          default:
            errorMessage = "Mauvais e-mail ou mot de passe"
        }
        setErrorLogin(errorMessage)
      });
  }


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Se connecter
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} >
              <MDInput type="email" label="E-mail" onChange={(e) => setEmail(e.target.value)} fullWidth />

            </MDBox>
            <MDBox mb={2} textAlign="center">
              <MDInput type="password" label="Mot de passe" onChange={(e) => setPassword(e.target.value)} fullWidth />
              <MDTypography variant="button" color="error">{errorLogin}</MDTypography>
            </MDBox>

            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" onClick={sigin} fullWidth>
                Se connecter
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Pas encore inscrit ?
                </MDTypography>
              </MDTypography>

            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/reset"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Mot de passe oublié ?
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
