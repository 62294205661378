// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDv9wa3QL7sLCKVrs6dI9aBp7ySy9Ly-e4",
    authDomain: "zenegopro.firebaseapp.com",
    databaseURL: "https://zenegopro-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "zenegopro",
    storageBucket: "zenegopro.appspot.com",
    messagingSenderId: "1027287416853",
    appId: "1:1027287416853:web:b6b4587a3571a6c1fb0e1c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);