import { child, get, ref, update, set } from "firebase/database";
import { db } from "config";
import * as moment from 'moment'
import * as axios from "axios";
// import { getAnalytics, setUserProperties } from "firebase/analytics";

const base = {
    // url: "http://localhost:5001/atempo-3da4a/us-central1/api"
    // url: 'https://us-central1-atempo-3da4a.cloudfunctions.net/api'
    urlPro: `${process.env.REACT_APP_URL_SERVER}`
}

const apiPro = axios.create({
    baseURL: base.urlPro,
});
/** Pour appeler les données de la base de données du praticien :
 * @param {String} - user  - Confirmation de la connexion d'un praticien.
 * @return {Objet} - Retourne les valeurs (infos) du praticien
 */
export async function getAllPractitionersData(user) {

    if (user) {
        console.log("user:", user);
        try {
            const snapshot = await get(child(ref(db), `/practitioners/${user.uid}`));
            if (snapshot.exists()) {

                return snapshot.val();
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export async function getAllEvents(user) {


    if (user) {
        try {
            const snapshot = await get(child(ref(db), `/practitioners/${user.uid}/events`));
            if (snapshot.exists()) {
                return snapshot.val();
            }
        } catch (error) {
            console.error(error);
        }
    }
}
/** Pour modifier les données de la base de données du praticien sur la page modif profil :
 * @param {String} - user  - Confirmation de la connexion d'un praticien.
 * @param {Objet} - data - les données du praticien
 **/
export async function updatePractitionersData(user, data) {
    const last_update = Date.now();
    if (user && data) {
        update(ref(db, `practitioners/${user.uid}`), {
            ...data,
            last_update,
        });
    }
}
export async function updateEventsPractitionersData(user, data) {
    const last_update = Date.now();
    if (user && data) {
        set(ref(db, `practitioners/${user.uid}/events`), {
            ...data
        });
    }
}
/** Pour récupérer les données de google à la connexion :
 * @param {String} - user  - Confirmation de la connexion d'un praticien.
 * @param {Objet} - googleData - les données de Google du praticien
 **/
export async function updateDataIfGoogleSignIn(user) {
    if (user.displayName) {
        const googleData = {
            firstname: user.displayName.split(" ")[0],
            lastname: user.displayName.split(" ")[1],
            email: user.email,
            photoURL: user.photoURL,
            client_nb: 0,
            seance_nb: 0,
            seance_size: 0,
        };
        await updatePractitionersData(user, googleData);
    }
}
export async function checkStatusSub(user) {
    if (user) {
        try {

            const snapshot = await get(child(ref(db), `/practitioners/${user.uid}`));
            const data = snapshot.val()
            // if (data.status) {
            //     const analytics = getAnalytics();
            //     setUserProperties(analytics, { status: data.status });
            // }
            // if (data.status === "TRIAL") {
            //     const now = moment()
            //     console.log(now.diff(data.created, 'days'));
            //     const statusTrial = 15 - now.diff(data.created, 'days')
            //     if (statusTrial < 0) {

            //         return "CANCELED"
            //     }
            //     return "TRIAL"
            // } else 
            if (data.stripe && data.stripe.subscription) {
                const statusSub = await apiPro.get(`/get-subscription/${data.stripe.subscription}`)
                if (statusSub.data.status === "active") {
                    await set(ref(db, `practitioners/${user.uid}/subscription`), {
                        ...statusSub.data
                    });
                    if (statusSub.data.plan.id === process.env.REACT_APP_PLAN_STARTER) {
                        await set(ref(db, `practitioners/${user.uid}/status`), "STARTER");
                        return "STARTER"
                    }
                    else if (statusSub.data.plan.id === process.env.REACT_APP_PLAN_PRO) {
                        await set(ref(db, `practitioners/${user.uid}/status`), "PRO");
                        return "PRO"
                    }
                    else if (statusSub.data.plan.id === process.env.REACT_APP_PLAN_ENTERPRISE) {
                        await set(ref(db, `practitioners/${user.uid}/status`), "ENTERPRISE");
                        return "ENTERPRISE"
                    }
                    else {
                        await set(ref(db, `practitioners/${user.uid}/status`), "FREE");
                        return "FREE"

                    }
                } else {
                    update(ref(db, `practitioners/${user.uid}`), {
                        status: "FREE",
                    });
                    return "FREE"
                }
            }
            else {
                return "FREE"

            }
        } catch (error) {
            console.error(error);
        }
    }
}