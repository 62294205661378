/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getAllPractitionersData } from "model/praticionersData";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import { Icon, Link, Table, TableRow, TableBody, Popover } from "@mui/material";
import MDInput from "components/MDInput";
import { useState, useEffect, useMemo } from "react";
import { useTable, useGlobalFilter, useSortBy, useAsyncDebounce } from "react-table";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/authContext";
import { getClientList } from "model/client";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function Tables() {
  let { columns, rows } = authorsTableData();
  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null);
  const [practitionersData, setPractitionersData] = useState();
  const [maxClient, setMaxClient] = useState("");
  const [clientNumber, setClientNumber] = useState(0);

  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const getData = async (user) => {
    const data = await getAllPractitionersData(user);
    setPractitionersData(data);
    setClientNumber(data.client_nb)
    switch (data.status) {
      case 'FREE':
        setMaxClient("5")
        break;
      case 'STARTER':
        setMaxClient("20")
        break;
      case 'PRO':
        setMaxClient("50")

        break;
      default:
        setMaxClient("5")

    }
  }
  const handleClick = (event) => {

    if (practitionersData.status === "FREE" && practitionersData.client_nb >= 5) {
      setAnchorEl(event.currentTarget);
    }
    else if (practitionersData.status === "STARTER" && practitionersData.client_nb >= 20) {
      setAnchorEl(event.currentTarget);

    } else if (practitionersData.status === "PRO" && practitionersData.client_nb >= 50) {
      setAnchorEl(event.currentTarget);

    } else {
      navigate("/clients/nouveau-client")

    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getData(user);

  }, [user]);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MDTypography variant="h6" color="dark">
                      Clients
                </MDTypography>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="h4">{clientNumber} </MDTypography>
                      {practitionersData && practitionersData.status != "ENTERPRISE" && <MDTypography variant="button" fontWeight="regular" color="dark"> /{maxClient}</MDTypography>}
                    </MDBox>

                  </Grid>

                  <Grid item xs={6}>

                    <MDBox  >
                      {/* <Link href="/clients/register-client"> */}
                      <MDButton aria-describedby={id} onClick={handleClick} variant="gradient" color="success" endIcon={<Icon fontSize="small">add</Icon>} fullWidth>
                        Ajouter Client
</MDButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >

                        <MDBox justifyContent="space-between" alignItems="center" pt={2} px={2} style={{ backgroundColor: "white" }}>
                          <MDBox display="flex" lineHeight={1}>
                            <MDTypography variant="button" justifyContent="center" textAlign='center' fontWeight="bold" color="dark">
                              Vous avez atteint la limite de clients pour votre plan. </MDTypography>
                          </MDBox>
                          <MDBox display="flex" justifyContent="center" lineHeight={1}>

                            <MDTypography variant="button" mb={2} fontWeight="bold" color="dark">
                              Améliorez votre plan pour en ajouter plus.</MDTypography>

                          </MDBox>
                          <MDBox display="flex" justifyContent="center" lineHeight={1}>
                            <MDButton aria-describedby={id} pt={2} onClick={() => navigate("/profil")} variant="gradient" color="secondary" endIcon={<Icon fontSize="small">rocket</Icon>}>
                              Améliorer
</MDButton>
                          </MDBox>
                        </MDBox>

                      </Popover>
                      {/* </Link> */}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* <MDBox pt={12}>
                <MDInput
                  placeholder="Recherche..."
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox> */}

              <MDBox pt={3}>

                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  // entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
