/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background1.jpg";
import { Grid } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import FaqCollapse from "components/FaqCollapse";
import { useState } from "react";

// const { createUser } = useAuth();



function Mentions() {
    const [collapse, setCollapse] = useState(false);

    return (
        <PageLayout>

            <MDBox
                width="calc(100% - 0.2rem)"
                borderRadius="xl"
                mx={2}
                my={2}
                pt={6}
                pb={28}

            />
            <MDBox mb={6} mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 0.2rem)" mx="auto">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={11} sm={10} md={10} lg={10} xl={10}>
                        <Card >
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                p={3}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    MENTIONS LÉGALES
                                </MDTypography>

                            </MDBox>
                            <Grid item xs={12} md={10} justifyContent="center">

                                <MDBox mx={2} mb={2} mt={2}>
                                    <MDTypography variant="body2" color="text">
                                        L'application Détendez-vous avec Zenego est éditée par TAIWA SAS, au capital de 3 000 €, entreprise répertoriée dans SIRENE sous le numéro d’immatriculation 848091799 auprès du RCS de LYON et dont le siège social est situé au 308 rue Paul Bert, 69003 Lyon.
                                        </MDTypography>
                                    <MDTypography variant="body2" color="text">

                                        N° de TVA intracommunautaire : FR38848091799.
                                        </MDTypography>
                                    <MDTypography variant="body2" color="text">

                                        Directeur de la publication : Manuel Briand, Directeur Général de TAIWA SAS, joignable à l'adresse contact@zenego.fr.
                                        </MDTypography>
                                    <MDTypography variant="body2" color="text">

                                        Les informations concernant la collecte et le traitement des données personnelles (politique et déclaration) sont fournies dans la charte de données personnelles de l'application.
                                        </MDTypography>
                                    <MDTypography variant="body2" color="text">

                                        Tous droits réservés - 21 octobre 2021
                                        </MDTypography>

                                </MDBox>
                            </Grid>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>

    );
}

export default Mentions;
