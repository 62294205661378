export const colors = {
    decouverte: "#BBCE95",
    stress: "#F3AF58",
    emotion: "#FF9083",
    sommeil: "#7991DB",
    grossesse: "#f5d479",
    enfant: "#E7C9A7",
    energie: "#F3E591",
    mental: "#FFC498",
    default: "#C5C5DB"

}

export function backgroundColor(thematic) {
    let backgroundColorTuile = ""
    switch (thematic) {
        case 'enfants':
            backgroundColorTuile = colors.enfant
            break
        case 'mental':
            backgroundColorTuile = colors.mental
            break
        case 'grossesse':
            backgroundColorTuile = colors.grossesse
            break
        case 'sommeil':
            backgroundColorTuile = colors.sommeil
            break
        case 'émotions':
            backgroundColorTuile = colors.emotion
            break
        case 'énergie':
            backgroundColorTuile = colors.energie
            break
        case 'découverte':
            backgroundColorTuile = colors.decouverte
            break
        case 'stress':
            backgroundColorTuile = colors.stress
            break
        default:
            backgroundColorTuile = colors.default


    }
    return backgroundColorTuile
}